import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RouteLoader from '../config/route/RouteLoader';
import './App.css';
import Alert from './components/Alert/Alert';
import ErrorBoundaries from './components/ErrorBoundaries';
import Loader from './components/Loader/Loader';

function App() {
  const { isLoading } = useSelector((store) => { return store.appdata });
  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('loading')
    } else {
      document.body.classList.remove('loading')
    }
  }, [isLoading])
  return (
    <ErrorBoundaries>
      {isLoading ? <Loader /> : ""}
      <Alert />
      <RouteLoader />
    </ErrorBoundaries>
  )
}

export default App;

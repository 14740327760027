import { makeSequreApiCall, METHOD_DELETE, METHOD_GET, METHOD_POST } from './callapi';
const prefix = '/api/v1/parent/order/'
const aprefix = '/api/v1/admin/order/'
const rprefix = '/api/v1/parent/recharge/'
const addcart = prefix + 'cart/';
const byid = prefix + 'cart/';

const paymenturl = 'https://test.ipg-online.com/connect/gateway/processing'
//https://prod.emea.api.fiservapps.com/ipp/payments-gateway/v2/payments
export const getadminorderlist = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(aprefix, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}

export const getorderlist = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}

export const getorderdetails = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix + payload.id, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}

export const createcart = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(addcart + payload.student_id, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}

export const addtocart = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(addcart + payload.student_id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const updatecart = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(addcart + payload.student_id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}

export const processpayment = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(paymenturl, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}

export const getcart = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(byid + payload.id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}

export const createtrasaction = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(rprefix + payload.rfid, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const updatetrasaction = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(addcart + payload.student_id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}

export const completeposcart = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix + "pos_complete/" + payload.order_id, METHOD_POST, payload).then(resolve).catch(reject);
    })
}
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { getRoutesByRoleId } from './RoutesConfig';
const PrivateRoutes = () => {
  const userdata = useSelector((store) => { return store.userdata })
  const routesList = getRoutesByRoleId(userdata.role_id);

  return (
    <Routes>
      {
        routesList?.map(({ path, Component, isDisabled, subRoutes = [] }, index) => {
          if (!Component || isDisabled) return null
          let path_key = path + "_" + index;
          return (
            <Fragment key={path_key}>
              <Route key={path_key} path={path} element={<Component />} />
              {subRoutes.map(({ path, Component }) => {
                if (!Component) return null
                return (<Route key={'sub_' + path} path={path} element={<Component />} />)
              })}
            </Fragment>
          );
        })
      }
    </Routes>
  );
};

export default PrivateRoutes;

import { makeSequreApiCall, METHOD_DELETE, METHOD_GET, METHOD_POST } from './callapi';

const api = '/api/v1/parent';
const admin_api = '/api/v1/admin';
const prefix = '/api/v1/parent/student';
const admin_prefix = '/api/v1/admin/students';
const getall = prefix;
const addnew = prefix;
const byid = prefix + '/';
const posstudentsurl = admin_api + '/pos-students';

export const getstudentslist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : getall, METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getPosStudentslist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : getall + '/pos', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getPosStudentslistForMealOrder = () => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(getall + '/pos/for_meals', METHOD_GET).then(resolve).catch(reject);
    })
}

export const getPosCardTransactionslist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : getall + '/pos/card-transaction', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getrechargelist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : api + '/recharge', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getrechargeAdminlist = (payload, force) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(admin_api + '/recharge', METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}

export const getadminstudentslist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : admin_prefix, METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getAdminPosStudentslist = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload?.url : '/api/v1/admin/pos-students', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getAdminPosStudentslistv2 = (payload, force) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(posstudentsurl, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}


export const addstudent = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(addnew, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const updatestudent = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(byid + payload.uuid, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const deletestudent = (payload) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(byid + payload.uuid, METHOD_DELETE, payload).then(resolve).catch(reject);
    })
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../../app/components/Alert/Alert";
import { logindata_key } from "../../appkeys";
import { setActiveMenu, setAlert, setLoading, setPagination } from "../appReducer/appReducer";
import { adduser, getallemployees, getallparents, getalluser, loginuser, logoutuser, registeruser, updateuser } from "../services/userservices"
import { getUserDetails, getDetailsComplimentary, getDetailsSchool, getDetailsMealselection, getComplimentaryBasedCategory, getDetailsStudent } from '../appReducer/appReducer';
const initialState = {
    email: '',
    password: '',
    role_name: 4,
    role_id: 4,
    accestoken: '',
    isAuth: false,
    createuserdata: {
        uuid: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        emirates_id: [],
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        role_id: '',
    },
    listdata: [],
    parentslist:[],
    stafflist:[],
    lastupdate: new Date().getTime()
}

const localState = () => {
    let hasData = localStorage.getItem(logindata_key)
    if (hasData) {
        try {
            return JSON.parse(hasData)
        } catch (err) {
            return {};
        }
    }
    return {}
}

export const getAllParents = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const { appdata } = getState();
            if(appdata.pagination?.total > 0 && url == ""){
                appdata.pagination.links.forEach((link) => {
                    if(link.active){
                        url = link.url;
                    }
                })
            }

            const response = await getallparents({url});     
            let parentsList = [];

            let pagination = [];
            pagination.current_page = response.current_page;
            pagination.last_page = response.last_page;
            pagination.first_page_url = response.first_page_url;
            pagination.last_page_url = response.last_page_url;
            pagination.next_page_url = response.next_page_url;
            pagination.prev_page_url = response.prev_page_url;
            pagination.path = response.path;
            pagination.per_page = response.per_page;
            pagination.from = response.from;
            pagination.to = response.to;
            pagination.total = response.total;
            pagination.links = response.links;
            console.log('parentslist re', parentsList)
            response.data.forEach((parent) => {
                const  { country_id, created_at, email, emirates_mapping, first_name, middle_name, image, last_name, phone, status, student_mapping, unique_id, uuid, role_mapping} = parent
                const emirates = emirates_mapping[0]?.emirates;
                const roles = role_mapping.role.id;
                let studentsList = [];
                student_mapping.forEach(({ students }) => {
                    const { basic, division, grade, school, school_unique_id, section } = students;
                    const emirates = basic?.emirates_mapping[0]?.emirates;
                    let studentData = {
                        uuid:basic.uuid,
                        value: basic.uuid,
                        label:basic.first_name,
                        first_name: basic.first_name,
                        middle_name: basic.middle_name,
                        last_name: basic.last_name,
                        country_id: basic.country.id,
                        country_name: basic.country.name,
                        emirates_id: emirates?.id,
                        emirates_name: emirates?.name,
                        school_id: school.id,
                        school_name: school.name,
                        school_unique_id: school_unique_id,
                        section_id: section.id,
                        section_name: section.name,
                        division_id: division.id,
                        division_name: division.name,
                        grade_id: grade.id,
                        grade_name: grade.name,
                        status:basic.status,
                    }
                    studentsList.push(studentData)
                })
                let parentdata = {
                    country_id:country_id,
                    created_at:created_at,
                    email:email,
                    emirates_mapping:emirates_mapping,
                    first_name:first_name,
                    middle_name:middle_name,
                    image:image,
                    last_name:last_name,
                    phone:phone,
                    role_mapping:roles.id,
                    status:status,
                    unique_id:unique_id,
                    uuid:uuid,
                    students: studentsList,

                }
                parentsList.push(parentdata)
            })
            console.log('parentslist re', parentsList)
            dispatch(setPagination({ pagination: pagination }))
            dispatch(setparentlist({ parentslist: parentsList }));
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

const userSlice = createSlice({
    name: 'parentdata',
    initialState: { ...initialState, ...localState() },
    reducers: {
        onloginsuccess: (state, action) => {
            return { ...state, ...action.payload };
        },
        onloginfaild: (state, action) => {
            return { ...state, ...action.payload };
        },
        onregisteruser: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetregister: (state, action) => { return { ...state, success: false, redirect: '' }; },
        onadduser: (state, action) => {
            return { ...state, ...action.payload }
        },
        setparentlist: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        onedit: (state, action) => {
            return {
                ...state,
                createuserdata: action.payload,
                isEdit: true,
            }
        },
        setformdata: (state, action) => {
            return { ...state, createuserdata: action.payload };
        },
        resetform: (state, action) => {
            return {
                ...state,
                createuserdata: initialState.createuserdata,
                isEdit: false
            };
        },
    },
})

export const { onlogin, onloginsuccess, onadduser, onloginfaild, onregisteruser, setparentlist, resetform, onedit, resetregister, setformdata } = userSlice.actions;

export default userSlice.reducer;
import axios from "axios";
import { logindata_key } from "../../appkeys";
export const METHOD_GET = 'get'
export const METHOD_POST = 'post'
export const METHOD_DELETE = 'delete'
export const api_interval = 5000;
const lastupdate = {};
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const getToken = () => {
    let hasData = localStorage.getItem(logindata_key)
    if (hasData) {
        try {
            let userdata = JSON.parse(hasData)
            return {
                token: userdata.accestoken,
                uuid: userdata.uuid
            }
        } catch (err) {
            return '';
        }
    }
    return ''
}

export const makeApiCall = async (url = '', method = METHOD_POST, data = {}) => {
    if (lastupdate[url] && lastupdate[url][method]) { return Promise.reject() }
    if (lastupdate[url]) {
        lastupdate[url][method] = true;
    } else {
        lastupdate[url] = { [method]: true }
    }
    setTimeout(() => { lastupdate[url] = { [method]: false } }, api_interval)
    let config = {
        url,
        method: method,
        params: data
    };
    try {
        const response = await axios(config);
        return response.data || response.response.data;
    } catch (reject) {
        return reject.data || reject.response.data;
    }
}

export const makeSequreApiCall = async (url = '', method = METHOD_POST, data = {}, force=false) => {
    if (!force && lastupdate[url] && lastupdate[url][method]) { return Promise.reject() }
    if (lastupdate[url]) {
        lastupdate[url][method] = true;
    } else {
        lastupdate[url] = { [method]: true }
    }
    setTimeout(() => { lastupdate[url] = { [method]: false } }, api_interval)
    let userdata = getToken();
    if (userdata.token) {
        let config = {
            url,
            headers: {
                "Authorization": `Bearer ${userdata.token}`
            },
            method: method,
            params: data
        };
        try {
            const response = await axios(config);
            return response.data || response.response.data;
        } catch (reject) {
            return reject.data || reject.response.data;
        }
    } else {
        return Promise.reject({ "message": "Un Authorized" });
    }
}
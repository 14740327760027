import { makeSequreApiCall, METHOD_GET, METHOD_POST, METHOD_DELETE, makeApiCall } from './callapi';
const configurl = '/envconfig.json';
const prefix = '/api/v1/admin/'
const detailsuser = prefix + 'details_for_add_user'
const detailscomplimentary = prefix + 'details_for_add_complimentary/'
const detailsschool = prefix + 'details_for_add_school/'
const detailsmealselection = prefix + 'details_for_add_meal_selection/'
const complimentarybasedcategory = prefix + 'complimentary_based_category/'
const detailsstudent = '/api/v1/parent/details_for_add_student/'

export const dashboardData = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall('/api/v1/dashboard', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getuserdetails = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(detailsuser, METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getdetailscomplimentary = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(detailscomplimentary, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getdetailsschool = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(detailsschool, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getdetailsmealselection = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(detailsmealselection, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getcomplimentarybasedcategory = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(complimentarybasedcategory+payload.id, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getdetailsstudent = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(detailsstudent, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getConfig = ()=>{
    return new Promise((resolve, reject)=>{
        return makeApiCall(configurl, METHOD_GET).then(resolve).catch(reject);
    })
}
import React, { lazy } from "react";
import Translate from "../../utils/Translate";
import { lan_key } from "../lan_keys";
// 1	Super Admin
// 2	Admin
// 3	Employee
// 4	Parent
// 5	Student
// 6	General User
// 7	Sub Admin

const openRoutes = [
  {
    path: "/activate/email/:id",
    accessRols: [1, 2, 3, 4, 6, 7],
    disabled: true,
    Component: lazy(() =>
      import("../../app/pages/ConfirmationTemplates/EmailActivationSuccess")
    ),
  },
  {
    path: "/confirmation/payment/:id",
    accessRols: [1, 2, 3, 4, 6, 7],
    disabled: true,
    Component: lazy(() =>
      import("../../app/pages/ConfirmationTemplates/PaymentSuccess")
    ),
  },
  {
    path: "/confirmation/payment/fail/:id",
    accessRols: [1, 2, 3, 4, 6, 7],
    disabled: true,
    Component: lazy(() =>
      import("../../app/pages/ConfirmationTemplates/PaymentFaild")
    ),
  },
  {
    path: "/confirmation/recharge/:id",
    accessRols: [1, 2, 3, 4, 6, 7],
    disabled: true,
    Component: lazy(() =>
      import("../../app/pages/ConfirmationTemplates/RechargeSuccess")
    ),
  },
  {
    path: "/confirmation/recharge/fail/:id",
    accessRols: [1, 2, 3, 4, 6, 7],
    disabled: true,
    Component: lazy(() =>
      import("../../app/pages/ConfirmationTemplates/RechargeFaild")
    ),
  },
  {
    path: "/meal-manage/order-now/:id",
    accessRols: [4],
    disabled: true,
    Component: lazy(() =>
      import("../../app/components/MealOrder/CreateMealOrder/MealOrder")
    ),
  },
  {
    path: "/meal-manage/meal-orders/:id",
    accessRols: [4],
    disabled: true,
    Component: lazy(() =>
      import("../../app/components/MealOrder/Meal-list/MealList")
    ),
  },
  {
    path: "/pos-meal-manage/order-now/:id",
    accessRols: [4],
    disabled: true,
    Component: lazy(() =>
      import("../../app/components/PosMealOrder/CreateMealOrder/MealOrder")
    ),
  },
  {
    path: "/pos-meal-manage/meal-orders/:id",
    accessRols: [4],
    disabled: true,
    Component: lazy(() =>
      import("../../app/components/PosMealOrder/Meal-list/MealList")
    ),
  },
];

const RoutesConfig = {
  publicRoutes: [
    ...openRoutes,
    {
      path: "/",
      Component: lazy(() => import("../../app/components/Login")),
    },
    {
      path: "/login",
      Component: lazy(() => import("../../app/components/Login")),
    },
    {
      path: "/reset",
      Component: lazy(() => import("../../app/components/ForgotPassword")),
    },
    {
      path: "/signup",
      Component: lazy(() => import("../../app/components/Registration")),
    },
    {
      path: "/verify-email",
      Component: lazy(() => import("../../app/components/VerifyEmail")),
    },
    {
      path: "*",
      Component: lazy(() => import("../../app/components/Login")),
    },
  ],
  privateRoutes: [
    ...openRoutes,
    {
      path: "/",
      name: <Translate lang_key={lan_key.dashboard} />,
      key: "t-default",
      icon: "bx bxs-dashboard",
      accessRols: [1, 2, 4, 6, 7],
      Component: lazy(() => import("../../app/v2/pages/Home")),
    },
    {
      path: "/students-list",
      name: <Translate lang_key={lan_key.pos_student_list} />,
      key: "t-user-list",
      icon: "bx bx-user-pin",
      accessRols: [4],
      Component: lazy(() => import("../../app/v2/pages/parent/pos-students")),
    },
    {
      path: "/history",
      name: "History",
      key: "t-contacts",
      icon: "mdi mdi-history",
      accessRols: [4],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
      subRoutes: [
        {
          path: "/history/recharge-transactions",
          name: "Recharge",
          key: "t-product-detail",
          icon: "mdi mdi-cash-check",
          accessRols: [4],
          Component: lazy(() =>
            import("../../app/v2/pages/parent/recharge/List")
          ),
        },
        {
          path: "/history/meal-orders",
          name: <Translate lang_key={lan_key.meal_orders} />,
          key: "t-product-detail",
          icon: "bx bx-basket",
          accessRols: [4],
          Component: lazy(() => import("../../app/v2/pages/parent/meal/List")),
        },
        {
          path: "/history/pos-meal-consumption",
          name: "POS Meal Consumption",
          key: "t-orders",
          icon: "mdi mdi-card-account-details-star",
          accessRols: [4],
          Component: lazy(() =>
            import("../../app/v2/pages/parent/meal-consumption/List")
          ),
        },
      ],
    },
    {
      path: "/settings",
      name: <Translate lang_key={lan_key.settings} />,
      key: "t-default",
      icon: "bx bxs-wrench",
      accessRols: [4],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
      subRoutes: [
        {
          path: "/settings/profile",
          name: <Translate lang_key={lan_key.profile} />,
          key: "t-default",
          icon: "mdi mdi-account-cog",
          accessRols: [4],
          Component: lazy(() => import("../../app/components/Users/Profile")),
        },
        {
          path: "/settings/password",
          name: <Translate lang_key={lan_key.password} />,
          key: "t-default",
          icon: "bx bx-key",
          accessRols: [4],
          Component: lazy(() => import("../../app/components/Users/Password")),
        },
      ],
    },
    //Admin Paths
    {
      path: "/user-meal-manage",
      name: "Meal Manage",
      key: "t-default",
      icon: "bx bx-dish",
      accessRols: [1, 2, 6, 7],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
      subRoutes: [
        {
          path: "/user-meal-manage/meal-order",
          name: "Portal Meal Orders",
          key: "t-default",
          icon: "mdi mdi-food",
          accessRols: [1, 2, 6, 7],
          Component: lazy(() =>
            import("../../app/v2/pages/admin/reports/MealOrder")
          ),
        },
        {
          path: "/user-meal-manage/create-meal-plan",
          name: "Ceate Meal Plan",
          key: "t-default",
          icon: "mdi mdi-food-variant",
          accessRols: [1, 2, 6, 7],
          Component: lazy(() =>
            import("../../app/components/MealManage/CreateMealPlan")
          ),
        },
        {
          path: "/user-meal-manage/meal-list",
          name: "Meal Plan List",
          key: "t-default",
          icon: "bx bx-food-menu",
          accessRols: [1, 2, 6, 7],
          Component: lazy(() =>
            import("../../app/v2/pages/admin/meal/List")
          ),
        },
      ],
    },
    {
      path: "/reports",
      name: "Reports",
      key: "t-default",
      icon: "bx bxs-report",
      accessRols: [1, 2, 7],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
      subRoutes: [
        {
          path: "/reports/recharge",
          name: "Recharge",
          key: "t-default",
          icon: "mdi mdi-account-cash",
          accessRols: [1, 2, 7],
          Component: lazy(() =>
            import("../../app/v2/pages/admin/reports/Recharge")
          ),
        },
        {
          path: "/reports/pos-meal-consumption",
          name: "POS Meal Consumption",
          key: "t-default",
          icon: "bx bx-food-menu",
          accessRols: [1, 2, 7],
          Component: lazy(() =>
            import("../../app/v2/pages/admin/reports/MealConsumption")
          ),
        },
        {
          path: "/reports/registered-parents",
          name: "Registered Parents",
          key: "t-default",
          icon: "bx bxs-user-plus",
          accessRols: [1, 2, 7],
          Component: lazy(() =>
            import("../../app/v2/pages/admin/reports/RegisteredParents")
          ),
        },
      ],
    },
    {
      path: '/user-manage',
      name: 'User Manage',
      key: "t-default",
      icon: 'bx bxs-user-detail',
      accessRols: [1, 2],
      Component: lazy(() => import('../../app/pages/Home/Home')),
      subRoutes: [
        {
          path: '/user-manage/create-user',
          name: 'Create User',
          key: "t-default",
          icon: 'bx bx-user-plus',
          accessRols: [1, 2],
          Component: lazy(() => import('../../app/components/Users/CreateUser')),
        },
        {
          path: '/user-manage/user-list',
          name: 'User List',
          key: "t-default",
          icon: 'bx bx-user-check',
          accessRols: [1, 2],
          Component: lazy(() => import('../../app/v2/pages/admin/user/List')),
        },
      ]
    },
    {
      path: "/masters",
      name: "Masters",
      key: "t-contacts",
      icon: "bx bxs-folder-plus",
      accessRols: [1, 2],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
      subRoutes: [
        {
          path: "/masters/complimentary",
          name: "Complimentary",
          key: "t-user-grid",
          icon: "mdi mdi-food",
          accessRols: [1, 2],
          Component: lazy(() =>
            import("../../app/components/Masters/Complimentary")
          ),
        },
        {
          path: "/masters/schools",
          name: "Schools",
          key: "t-user-grid",
          icon: "mdi mdi-school",
          accessRols: [1, 2],
          Component: lazy(() => import("../../app/components/Masters/Schools")),
        },
      ],
    },
    {
      path: "/user-settings",
      name: "Settings",
      key: "t-default",
      icon: "bx bxs-wrench",
      accessRols: [1, 2, 6, 7],
      Component: lazy(() =>
        import("../../app/components/Settings/SettingsHome")
      ),
      subRoutes: [
        {
          path: "/user-settings/password",
          name: "Password",
          key: "t-default",
          icon: "bx bx-key",
          accessRols: [1, 2, 6, 7],
          Component: lazy(() =>
            import("../../app/components/Settings/Password")
          ),
        },
      ],
    },
    {
      path: "*",
      disabled: true,
      accessRols: [1, 2, 3, 4, 5, 6, 7],
      Component: lazy(() => import("../../app/components/NotFoundComponent")),
    },
  ],
};

const splitMenuById = (id, menulist) => {
  let newmenulist = [];
  menulist.map((menu) => {
    let newmenu = {};
    Object.assign(newmenu, menu);
    if (menu.subRoutes) {
      newmenu.subRoutes = splitMenuById(id, menu.subRoutes);
    }
    if (menu?.accessRols?.indexOf(id) > -1) {
      newmenulist.push(newmenu);
    }
  });
  return newmenulist;
};
export const getRoutesByRoleId = (id) => {
  return splitMenuById(id || 2, RoutesConfig.privateRoutes);
};

export const getPublicRoutes = () => RoutesConfig.publicRoutes;

export const getOpenRoutes = () => RoutesConfig.openRoutes;

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Spinner from '../../app/components/Spinner';
import PublicLayout from '../../app/layout/PublicLayout';
import PrivateLayout from '../../app/layout/PrivateLayout';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux';
import Loader from '../../app/components/Loader/Loader';
const IS_SECOUND = 'IS_SECOUND'
const RouteLoader = () => {
  const state = useSelector((store)=>{return store.userdata})
  const isAuth = state?.isAuth;
  return (
    <Router basename="/">
      <Suspense fallback={<Loader />}>
        {isAuth ? (
          <PrivateLayout>
            <PrivateRoutes />
          </PrivateLayout>
        ) : (
          <PublicLayout>
            <PublicRoutes />
          </PublicLayout>
        )}
      </Suspense>
    </Router>
  );
};

export default RouteLoader;

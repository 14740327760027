import { createSlice } from "@reduxjs/toolkit"
const initialState = {
    data:[],
    parentsList:[],
    isLoading:true,
    totalRows:0,
    filters:{
        page:1, 
        per_page:10,
        sort_by : null,
        sort_type: null,
        filter_by: null,
        from_date: null,
        to_date: null,
        key_word: null,
        pos_student_id: null,
        parent_email_id:null
    },
    adv_filters:{
        filter_by: null,
        from_date: null,
        to_date: null,
        key_word: null,
        pos_student_id: null,
        parent_email_id:null
    },
    report_token : null,
    cancelStatus:null,
    refundStatus:null,
    defaultExpanded:null,
    itemDeleted:false
}
const dataTableSlice = createSlice({
    name: 'datatableslice',
    initialState,
    reducers: {
        setData: (state, action) => {
            return { ...state, data: action.payload };
        },
        setParentList: (state, action) => {
            return { ...state, parentsList: action.payload };
        },
        resetData: (state) => {
            return {
                ...state,
                ...initialState
            };
        },
        setLoading: (state, action) => ({ ...state, isLoading: action.payload }),
        setTotalRows: (state, action) => ({ ...state, totalRows: action.payload }),
        setReportToken: (state, action) => ({ ...state, report_token: action.payload }),
        setFilter: (state, action) => {
            return { ...state, filters: action.payload };
        },
        setAdvFilter: (state, action) => {
            return { ...state, adv_filters: action.payload };
        },
        setCancelStatus: (state, action) => ({ ...state, cancelStatus: action.payload }),
        setRefundStatus: (state, action) => ({ ...state, refundStatus: action.payload }),
        setDeleteStatus: (state, action) => ({ ...state, itemDeleted: action.payload }),
        setDefaultExp: (state, action) => ({ ...state, defaultExpanded: action.payload }),
    },
})

export const { 
    setData, 
    resetData,
    setParentList,
    setLoading,
    setTotalRows,
    setFilter,
    setAdvFilter,
    setCancelStatus,
    setRefundStatus,
    setDeleteStatus,
    setDefaultExp,
    setReportToken
} = dataTableSlice.actions;
export default dataTableSlice.reducer;
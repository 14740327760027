import { createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../app/components/Alert/Alert";
import { getSelectedValue } from "../../app/utils/helper";
import { setAlert, setLoading, setPagination } from "./appReducer/appReducer";
import { create, deletemeal, getall, update } from "./services/mealservices";
import { logoutUser } from "./userReducer/userReducer";

const initialState = {
    data: {
        name: "",
        arabic_name: "",
        nutrition: "",
        nutrition_arabic: "",
        school_ids: '',
        meal_category_id: 1,
        complimentery: [],
        allergens: '',
        allergens_arabic: '',
        description: '',
        description_arabic: '',
        price: '',
        date: new Date()
    },
    listdata: [],
    isEdit: false,
    lastupdate: new Date().getTime()
}

export const getAll = (payload, force=false) => {
    return async (dispatch, getState) => {
        
        try {
            dispatch(setLoading(true))
            const { appdata } = getState();
            const response = await getall(payload, force);
            if (response) {
                if (response?.total > 0 && response?.data.map) {
                    const { meal_category} = appdata;
                    let mealList = [];
                    response.data.forEach((meal) => {
                        const { id, name, arabic_name, school_mapping, price, meal_category_id } = meal;
                        const schools_ids = school_mapping.map((em) => { return em.schools.id });
                        const schools_name = school_mapping.map((emr) => { return emr.schools.name }).join(', ')
                        if (schools_name.length > 1) {
                            for (let i = 0; i < schools_name.length + 2; i++) {
                                // schools_name[i] = schools_name[i].concat(", ");
                            }
                        }
                        let _meal = {
                            ...meal,
                            value: id,
                            label: name,
                            ar_label: arabic_name,
                            school_ids: schools_ids,
                            school_name: schools_name,
                            meal_category_name: getSelectedValue(meal_category_id, meal_category).label
                        }
                        mealList.push(_meal)
                    })
                    dispatch(setPagination({ apipagination: {
                        total: response.total,
                        page : response.current_page,
                        per_page: response.per_page
                    } }))
                    // dispatch(setPagination({ pagination: pagination }))
                    dispatch(setlist({ listdata: mealList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const createMeal = (payload, isCopy = false) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            let _payload = {
                id: payload.id,
                name: payload.name,
                arabic_name: payload.arabic_name,
                nutrition: payload.nutrition,
                nutrition_arabic: payload.nutrition_arabic,
                complimentery: payload.complimentery,
                allergens: payload.allergens,
                allergens_arabic: payload.allergens_arabic,
                description: payload.description,
                description_arabic: payload.description_arabic,
                schools_ids: payload.school_ids,
                meal_category_id: payload.meal_category_id,
                price: payload.price,
                date: payload.date,
                image: payload.image,
            }
            const response = isCopy ? await create(_payload) :
                (payload.id ? await update(_payload) : await create(_payload));
            if (response.status) {
                await dispatch(getAll())
                await dispatch(resetform(_payload))
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj))
            } else {
                let message = response.message
                if (response.errors) {
                    message = Object.values(response.errors)[0][0]
                }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const deleteMeal = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await deletemeal(payload);
            if (response.status) {
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj));
                dispatch(getAll())
            } else {
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: response.message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

const mealSlice = createSlice({
    name: 'mealslice',
    initialState,
    reducers: {
        setformdata: (state, action) => {
            return { ...state, data: action.payload };
        },
        onedit: (state, action) => {
            return {
                ...state,
                data: action.payload,
                isEdit: true
            };
        },
        oncopy: (state, action) => {
            return {
                ...state,
                data: action.payload,
                isEdit: false
            };
        },
        resetform: (state, action) => {
            return {
                ...state,
                data: {
                    ...initialState.data,
                    name: "",
                    school_id: action?.payload?.schools_ids,
                    meal_category_id: action?.payload?.meal_category_id,
                    price: 0.00,
                    date: action?.payload?.date
                },
                isEdit: false
            };
        },
        getlist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setlist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setdetails: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
})

export const { setformdata, onedit, oncopy, resetform, getlist, setlist } = mealSlice.actions;

export default mealSlice.reducer;
import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend) 
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: 'ar',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },    
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
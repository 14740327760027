import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { getPublicRoutes } from './RoutesConfig';

const PublicRoutes = () => {
  const routesList = getPublicRoutes();
  return (
    <Routes>
      {
        routesList.map(({ path, Component }) => {
          if (!Component) return null
          return (
            <Route key={path} path={path} element={<Component />} />
          );
        },
        )
      }
      <Route path="/" />
    </Routes>
  );
};

export default PublicRoutes;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../config/store/userReducer/userReducer";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";
import Avatar from "../components/Avatar";
import { lan_key as lan_keys } from "../../config/lan_keys";

const lan_key = "lan_key";

const Header = () => {
  const userdata = useSelector((store) => {
    return store.userdata;
  });
  const isMobile = window.screen.width < 1024;
  const maincontent = document.getElementById("main-content");
  const [isActive, setIsActive] = useState(false);
  const [rightbar, toggleRightBar] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClickMenu = () => {
    const isSidebarEnable = document.body.classList.contains("sidebar-enable");
    if (isSidebarEnable) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    isActive
      ? document.body.classList.add("sidebar-enable")
      : document.body.classList.remove("sidebar-enable");
    isActive && !isMobile
      ? document.body.classList.add("vertical-collpsed")
      : document.body.classList.remove("vertical-collpsed");
  };

  useEffect(() => {
    let lang = localStorage.getItem(lan_key);
    document.getElementById("en_btn")?.classList.remove("act");
    document.getElementById("ar_btn")?.classList.remove("act");
    if (lang) {
      lang == "ar"
        ? document.body.classList.add("arabic_body")
        : document.body.classList.remove("arabic_body");
      document.getElementById(lang + "_btn")?.classList.add("act");
    } else {
      document.getElementById("en_btn")?.classList.add("act");
    }
    i18n.changeLanguage(lang);
  }, []);

  const onChangeLang = (lang) => {
    document.getElementById("en_btn")?.classList.remove("act");
    document.getElementById("ar_btn")?.classList.remove("act");
    document.getElementById(lang + "_btn")?.classList.add("act");
    lang == "ar"
      ? document.body.classList.add("arabic_body")
      : document.body.classList.remove("arabic_body");
    localStorage.setItem(lan_key, lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    isActive
      ? document.body.classList.add("sidebar-enable")
      : document.body.classList.remove("sidebar-enable");
    isActive && !isMobile
      ? document.body.classList.add("vertical-collpsed")
      : document.body.classList.remove("vertical-collpsed");
  }, [isActive]);

  useEffect(() => {
    if (maincontent) {
      maincontent.addEventListener("mousedown", (e) => {
        if (isMobile) {
          setIsActive(false);
        }
      });
    }
  }, [maincontent]);

  useEffect(() => {
    rightbar
      ? document.body.classList.add("right-bar-enabled")
      : document.body.classList.remove("right-bar-enabled");
  }, [rightbar]);

  return (
    <div id="layout-wrapper">
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
              <a href="/" className="logo">
                <span className="logo-sm">
                  <img src="/assets/images/icon.png" alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height="45" />
                </span>
              </a>
            </div>
            <button
              onClick={onClickMenu}
              className="btn btn-sm mt-1 font-size-20 header-item waves-effect "
              type="button"
              id="vertical-menu-btn"
            >
              <i className="fz_20 dripicons-align-left"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {userdata.role_id === 4 && (
              <>
                <div className="dropdown d-inline-block">
                  <div className="pro_btn">
                    <a
                      href="https://www.freshbitepc.ae/weeklymenuspza/"
                      target="_blank"
                      className="animated-button1"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <i className="bx bxs-food-menu"></i> View Menu
                    </a>
                  </div>
                </div>
                <div className="dropdown d-inline-block">
                  <div className="languagebar">
                    <a
                      href="#"
                      id="en_btn"
                      onClick={() => {
                        onChangeLang("en");
                      }}
                      className="cursor_pointer act"
                    >
                      ENG
                    </a>
                    <a
                      href="#"
                      id="ar_btn"
                      onClick={() => {
                        onChangeLang("ar");
                      }}
                      className="cursor_pointer arab_br"
                    >
                      العربية
                    </a>
                  </div>
                </div>
              </>
            )}
            {/* <div className="dropdown d-inline-block">
                            <button onClick={onClickSettings} type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="bx bx-cog bx-spin"></i>
                            </button>
                        </div> */}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                style={{ display: "none" }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-bell"></i>
                {/* <span className="badge bg-danger rounded-pill">5</span> */}
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0">Portal informations</h6>
                    </div>
                    <div className="col-auto"></div>
                  </div>
                </div>
                <div className="p-2 border-top d-grid">
                  <div className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-secondary rounded-circle font-size-16">
                          <i className="mdi mdi-bell-check"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">
                          {" "}
                          Online pre-orders must be made a day prior to delivery
                          before 3:00 PM.
                        </h6>
                      </div>
                    </div>
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-secondary rounded-circle font-size-16">
                          <i className="mdi mdi-bell-check"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">
                          After a successful Online Recharge payment, Students
                          balance will be updated within{" "}
                          <strong>ne business day.</strong>o
                        </h6>
                      </div>
                    </div>
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-secondary rounded-circle font-size-16">
                          <i className="mdi mdi-bell-check"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">
                          For all Enquires to Contact FBPC to Call US:{" "}
                          <strong>+971 52 411 6883</strong> and Email Us:{" "}
                          <strong>info@freshbitepc.ae</strong>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Avatar
                  type={userdata.role_id === 4 ? "p" : "a"}
                  size="xs"
                  wrap="no"
                />
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  {userdata.first_name}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {userdata.role_id !== 4 && (
                  <>
                    <a className="dropdown-item" href="#">
                      <i className="bx bx-user font-size-16 align-middle me-1"></i>
                      <span key="t-profile"> {userdata.role_name}</span>
                    </a>
                    <div className="dropdown-divider"></div>
                  </>
                )}
                {/* <a className="dropdown-item d-block right-bar-toggle waves-effect" href="#"> <i className="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span></a>
                                <a className="dropdown-item" href="#"><i className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a> */}

                <a
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                  href="#"
                  to={"/"}
                  className="dropdown-item text-danger"
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                  <span key="t-logout">{t(lan_keys.logout)}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;

import React from 'react';
import moment from 'moment'

export const daystring = ['Monday', 'Tuesday', 'Wednesday', 'Thuseday', 'Friday', 'Saterday', 'Sunday']
export const monthstring = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec']
export const isEqualDate = (date1, date2)=> {
    if(date1 && date2){
        return moment(date1).format('DDMMYYYY') === moment(date2).format('DDMMYYYY')
    }
    return false    
}
export const getDateString = (date) =>{
    if(date){
        return moment(date).format('DD-MM-YYYY')
    }
    return '';
}
export const validateObj = (formdata={})=>{
    let errorObj = []
    if(formdata){
        let keys = Object.keys(formdata)
        if(keys.length > 0){
            keys.map(formkey=>{
                if(!formdata[formkey]){
                    errorObj.push(formkey)
                }
            })
        }
    }
    return errorObj;
}

export const getSelectedValue = (value, data, isMulti)=>{
    if(isMulti && value && data?.length > 0){
        let filtered = data?.filter && data?.filter((arr)=>{return value.includes(arr.value)});
        return filtered || []
    } else if(value && data?.length > 0){
        let filtered = data?.filter((arr)=>{return arr.value === value})
        return filtered[0] || []
    }
    return []
}

export const getMealCategroyBadge = (label) =>{
    if(label.includes('( Veg )')){
        return '( Veg )'
    } else if(label.includes('( Non-Veg )')){
        return '( Non-Veg )'
    } else if(label.includes('( Starter )')){
        return '( Starter )'
    } else if(label.includes('( Budget )')){
        return '( Budget )'
    }
    
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const getIdFromUrl = url =>{
    let _url = url || window.location.pathname;
    const strs = _url.split('/');
    return strs.at(-1)
}

export const getPaymentStatus = (value) =>{
    switch(value){
        case '1':
            return "success" 
        case "5993":
            return "cancelled"
        case "50716":
            return "declined"
        default:
            return "failed"
    }
}
import React from "react";
import { useTranslation } from 'react-i18next';

const Translate = ({lang_key}) => {
    const { t } = useTranslation();
    return (
        <>{t(lang_key)}</>
    )
}

export default Translate;
import { makeApiCall, makeSequreApiCall, METHOD_GET, METHOD_POST, METHOD_DELETE } from './callapi';

const prefix = '/api/v1/auth/';
const register = prefix + 'register';
const login = prefix + 'login';
const logout = prefix + 'logout';
const uprefix = '/api/v1/admin/user/';
const pprefix = '/api/v1/admin/parents/';
const eprefix = '/api/v1/admin/employee/';
const email_verify = '/api/v1/email_verification/';
const email_resent = '/api/v1/email_resend';
const reset_password = '/api/v1/reset_password';
const user_reset_password = '/api/v1/user_reset_password';

export const registeruser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeApiCall(register, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const loginuser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeApiCall(login, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const logoutuser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(logout, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const adduser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(uprefix, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const updateuser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(uprefix + payload.uuid, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const getalluser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(payload?.url ? payload.url : uprefix, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const deleteuser = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(uprefix + payload.uuid, METHOD_DELETE, payload).then(resolve).catch(reject);
    })
}
export const getallparents = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(payload?.url ? payload.url : pprefix, METHOD_GET, payload).then(resolve).catch(reject);
    })
}
export const getallemployees = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(payload?.url ? payload.url : eprefix, METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const emailverification = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeApiCall(email_verify+ payload, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const emailsecureverification = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(email_verify+ payload, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const sendVerificationMail = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(email_resent, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const resetPassword = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeApiCall(reset_password, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const UserPasswordResetApi = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(user_reset_password, METHOD_POST, payload).then(resolve).catch(reject);
    })
}
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
const PublicLayout = (props)=> {
  const {isAuth, role_id, email_verified} = useSelector((store)=>{return store.userdata})
  const {activemenu} = useSelector((store)=>{return store.appdata})
  const navigate = useNavigate();
  const location = useLocation();
  if(isAuth && 
      (role_id == 4 || role_id == 3) && 
      email_verified == "0" && 
      location.pathname != "/verify-email" && 
      !location.pathname.includes("/activate/email/")){
    navigate("/verify-email");
  }
  useEffect(()=>{
    if (activemenu !== window.location.pathname) {
        navigate(activemenu)
    }
},[activemenu])
  
  return (
    <div className="auth-full-bg1 account-pages my-0 pt-5">
      <div className="container">
        {props.children}
      </div>
    </div>
  );
};

export default PublicLayout;

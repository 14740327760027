import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import { alertTypes } from "../../app/components/Alert/Alert";
import { setAlert, setLoading, setDetailData, setPagination } from "./appReducer/appReducer";
import {
  addtocart,
  createcart,
  getadminorderlist,
  getorderlist,
  createtrasaction,
  completeposcart
} from "./services/cartservices";
import {
  addmenu,
  deletemenu,
  getmenulist,
  getCategorylist,
  updatemenu,
} from "./services/menuservices";
import { logoutUser } from "./userReducer/userReducer";

const initialState = {
  data: {},
  isEdit: false,
  listdata: [],
  orderdata: {},
  rechargedata:{},
  orderhistory: [],
  cartitems: [],
  addtocart: {
    order_id: "",
    parent_id: "",
    student_id: "",
    school_id: "",
  },
  lastupdate: new Date().getTime(),
};

export const getMenuList = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      await dispatch(setdatalist({ listdata: [] }));
      await dispatch(setDetailData({ meal_category: [] }));
      const response = await getmenulist(payload);
      if (response) {
        if (response.length && response.map) {
          let list = [];
          response.forEach((menu_list) => {
            if (menu_list.meals.length) {
              menu_list.meals.forEach((menu) => { 
                let _menu = {
                  id: menu.id,
                  value: menu.id,
                  label: menu.name,
                  arabic_label: menu.arabic_name,

                  nutrition: menu.nutrition,
                  nutrition_arabic: menu.nutrition_arabic,
                  allergens: menu.allergens,
                  allergens_arabic: menu.allergens_arabic,
                  description: menu.description,
                  description_arabic: menu.description_arabic,

                  image: menu.image,
                  price: menu.price,
                  category: menu.category.id,
                  category_name: menu.category.name,
                  category_group: menu.category.category_parent_id,
                  date: menu.date,
                  currency: "AED",
                };
                list.push(_menu);
              });
            }
          });
          await dispatch(setdatalist({ listdata: list }));
        }

        const cat_response = await getCategorylist(payload);
        if (cat_response) {
          if (cat_response.length && cat_response.map) {
            let categorylist = [];
            cat_response.forEach((_category) => {
              categorylist.push(_category);
            });
            let meal_category = categorylist.map((obj) => {
              obj.value = obj.id;
              obj.label = obj.name;
              obj.key = "meal_category_id";
              return obj;
            });
            await dispatch(setDetailData({ meal_category: meal_category }));
          }
        }
        if (cat_response === "Unauthorized.") {
          dispatch(logoutUser());
        }
      }
      if (response === "Unauthorized.") {
        dispatch(logoutUser());
      }
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const addMenu = (formdata) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      let _formdata = { ...formdata, emirates_id: [formdata.emirates_id] };
      let isEdit = formdata?.uuid?.length > 0;
      const response = isEdit
        ? await updatemenu(_formdata)
        : await addmenu(_formdata);
      if (response.status) {
        let alertObj = {
          alert: {
            show: true,
            type: alertTypes.customsuccess,
            message: response.message,
          },
        };
        dispatch(setAlert(alertObj));
        dispatch(getMenuList());
        dispatch(resetform());
      } else {
        let message = response.message;
        if (response.errors) {
          message = Object.values(response.errors)[0][0];
        }
        let alertObj = {
          alert: { show: true, type: alertTypes.danger, message: message },
        };
        dispatch(setAlert(alertObj));
      }
      if (response === "Unauthorized.") {
        dispatch(logoutUser());
      }
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const deleteMenu = (formdata) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      let _formdata = { ...formdata, emirates_id: [formdata.emirates_id] };
      const response = await deletemenu(_formdata);
      if (response.status) {
        let alertObj = {
          alert: {
            show: true,
            type: alertTypes.customsuccess,
            message: response.message,
          },
        };
        dispatch(setAlert(alertObj));
        dispatch(getMenuList());
        dispatch(resetform());
      } else {
        let alertObj = {
          alert: {
            show: true,
            type: alertTypes.danger,
            message: response.message,
          },
        };
        dispatch(setAlert(alertObj));
      }
      if (response === "Unauthorized.") {
        dispatch(logoutUser());
      }
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const getAllOrderHistory = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await getorderlist(payload); console.log(response);
      const _orderhistory = [];
      response.data.map((order) => {
        const {
          meal_cart,
          parent,
          pos_student,
          school,
          order_id,
          status,
          updated_at
        } = order;

        meal_cart.map((cart) => {
          const _orderdata = {
            order_id: order_id,
            school_id: school.id,
            school_name: school.name,
            school_location: school.location,
            parent_id: parent.id,
            parent_name: `${parent.first_name} ${parent.last_name}`,
            student_id: pos_student.id,
            student_name: `${pos_student.first_name} ${pos_student.last_name}`,
            section: pos_student.section,
            grade: pos_student.grade,
            division: pos_student.division,
            student_image: null,
            country:pos_student.country,
            meal_category_name: cart.meal_category,
            meal_name: cart.name,
            price: cart.price,
            date: cart.date,
            order_date: updated_at,
            status: status,
          };
          _orderhistory.push(_orderdata);
        });
      });
      await dispatch(setorderhistory(_orderhistory));
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const getAllOrderAdmin = (payload, force=false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await getadminorderlist(payload, force);
      const _orderhistory = [];
      response.data.map((order) => {
        const {
          meal_cart,
          parent,
          pos_student,
          school,
          order_id,
          updated_at,
          status,
        } = order;

        meal_cart.map((cart) => {
          const _orderdata = {
            order_id: order_id,
            school_id: school.id,
            school_name: school.name,
            school_location: school.location,
            parent_id: parent.id,
            parent_email_id : parent.email,
            parent_name: `${parent.first_name} ${parent.last_name}`,
            student_id: pos_student.id,
            student_name: `${pos_student.first_name} ${pos_student.last_name}`,
            section: pos_student.section,
            grade: pos_student.grade,
            division: pos_student.division,
            student_image: null,
            country:pos_student.country,
            meal_category_name: cart.meal_category,
            name: cart.name,
            price: cart.price,
            date: cart.date,
            order_date: updated_at,
            status: status,
          };
          _orderhistory.push(_orderdata);
        });
      });
      dispatch(setPagination({ apipagination: {
        total: response.total,
        page : response.current_page,
        per_page: response.per_page
    } }))
      await dispatch(setorderhistory(_orderhistory));
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const addToCart = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await addtocart(payload);
      let _data = {
        ...payload,
        order_id: response.order.order_id,
        error: response.order?.error,
        api_host: process.env.REACT_APP_API_HOST,
        sharedsecret: process.env.REACT_APP_SS,
        storename: process.env.REACT_APP_API_SN,
        api_url: process.env.REACT_APP_PAYMENT_URL,
        api_auth: process.env.REACT_APP_PAYMENT_AUTH
      };
      sessionStorage.setItem("order-data", JSON.stringify(_data));
      await dispatch(setorderdata(_data));
    } catch (err) {}
  };
};

export const submitPayment = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await addtocart(payload);
      let _data = {
        ...payload,
        order_id: response.order.order_id,
      };
      await dispatch(setorderdata(_data));
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const completePosCart = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await completeposcart(payload);
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const getCart = (payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await createcart(payload);
      let alertObj = {
        alert: { show: true, type: alertTypes.danger, message: "" },
      };
      dispatch(setAlert(alertObj));
    } catch (err) {}
    dispatch(setLoading(false));
  };
};

export const createTrasaction = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await createtrasaction(payload)
      let _data = {
        ...payload,
        order_id: response.order.order_id,
        transaction_id: response.order.transaction_id,
        api_host: process.env.REACT_APP_API_HOST,
        sharedsecret: process.env.REACT_APP_SS,
        storename: process.env.REACT_APP_API_SN,
        api_url: process.env.REACT_APP_PAYMENT_URL,
        api_auth: process.env.REACT_APP_PAYMENT_AUTH
      };
      sessionStorage.setItem("recharge-data", JSON.stringify(_data));
      await dispatch(setrechargedata(_data));
    } catch (err) {}
  };
};

const menuSlice = createSlice({
  name: "menudata",
  initialState,
  reducers: {
    setformdata: (state, action) => {
      return { ...state, data: action.payload };
    },
    setorderdata: (state, action) => {
      return { ...state, orderdata: action.payload };
    },
    setrechargedata: (state, action) => {
      return { ...state, rechargedata: action.payload };
    },
    onedit: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isEdit: true,
      };
    },
    resetform: (state, action) => {
      return {
        ...state,
        data: initialState.data,
        isEdit: false,
      };
    },
    setdatalist: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCartItem: (state, action) => {
      return { ...state, cartitems: action.payload };
    },
    setorderhistory: (state, action) => {
      return { ...state, orderhistory: action.payload };
    },
  },
});

export const {
  setformdata,
  setdatalist,
  onedit,
  resetform,
  setCartItem,
  setorderdata,
  setrechargedata,
  setorderhistory,
} = menuSlice.actions;

export default menuSlice.reducer;

import { createPaymentUrl, deletePaymentUrl, getPaymentUrlStatus, makeSequreApiCall, METHOD_DELETE, METHOD_GET, METHOD_POST } from './callapi';
const prefix = '/api/v1/parent/menu-selection/'
const getall = prefix;
const addnew = prefix;
const byid = prefix;
const status = prefix + 'status/';

export const getmenulist = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(prefix+payload.id, METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const getCategorylist = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall('/api/v1/parent/meal-category', METHOD_GET, payload).then(resolve).catch(reject);
    })
}

export const addmenu = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(addnew, METHOD_POST, payload).then(resolve).catch(reject);
    })
}

export const updatemenu = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(byid + payload.uuid, METHOD_POST, payload).then(resolve).catch(reject);
    })
}
export const changestatus = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(status + payload.uuid, METHOD_DELETE, payload).then(resolve).catch(reject);
    })
}
export const deletemenu = (payload)=>{
    return new Promise((resolve, reject)=>{
        return makeSequreApiCall(byid + payload.uuid, METHOD_DELETE, payload).then(resolve).catch(reject);
    })
}
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import { lan_key } from "../../config/lan_keys";
import './PopupModel/popupmodel.scss'
const PrivacyPopup = () => {
  const { userdata } = useSelector((store) => { return store });
  const { role_id } = userdata;
  // const { t } = useTranslation();
  const [showmodel, toggleModel] = useState(false);

  useEffect(() => {     
    const last_accepted = localStorage.getItem("privacy_last_accepted") || 0;
    var  difference_in_days = 2;
    if(last_accepted !== 0){
      var timeEnd = new Date().getTime(); 
      difference_in_days = (timeEnd - last_accepted) / (1000 * 3600 * 24);
    }
  
    if(difference_in_days > 1){
      toggleModel(true)
    }
  }, []);

  const onClose = () => {
    localStorage.setItem("privacy_last_accepted", new Date().getTime());
    toggleModel(false)
  }

  return (
    role_id === 4 && showmodel &&
      <div className='showmodel'>
        <div className="modal " tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-12 div_privacy">
                      <h4 class="mb-3 font-size-18">Privacy Policy</h4>
                      <ol className="col-12"><li>By submitting information, data, passwords, user names, other log-in information, materials and other content to us, you are granting Fresh Bite Catering LLC the right to use these for the purpose of providing the services. We may use and store the content in accordance with this terms &amp; conditions, without any obligation by us to pay any fees or be subject to any restrictions or limitations. By using the services, you expressly authorize Fresh Bite Catering LLC to access your account information and payment information and submit the same further for processing your payments to any payment gateway to facilitate your payment for our services.</li><li>As you will be using the services of Payment System Providers and Card Associations to process your payment instructions, you consent and agree to comply with the rules, guidelines, directions, instructions, requests, etc. made by the Payment System Providers and Card Associations from time to time. You expressly acknowledge and agree that you are assuming the risk of compliance with all applicable Guidelines. If you fail to comply with your obligations towards the Payment System Providers, we may suspend or terminate your Cashless Payment Account.</li>
                      </ol>
                      <p className='text-center'>
                      <button onClick={onClose} className="btn btn-mwid btn-success waves-effect waves-light btn-lg">Continue <i className="mdi mdi-chevron-right"></i></button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" />
        </div>
      </div>
  )
}

export default PrivacyPopup;

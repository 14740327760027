import { createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../app/components/Alert/Alert";
import { getPaymentStatus } from "../../app/utils/helper";
import { logindata_key } from "../appkeys";
import { setActiveMenu, setAlert, setLoading, setPagination } from "./appReducer/appReducer";
import { addstudent, deletestudent, getstudentslist, getPosStudentslist, getPosStudentslistForMealOrder, getPosCardTransactionslist, getadminstudentslist, getAdminPosStudentslist, updatestudent, getrechargelist, getrechargeAdminlist, getAdminPosStudentslistv2 } from "./services/studentservices";
import { logoutUser } from "./userReducer/userReducer";

const initialState = {
    activetab: 0,
    studentdata: {
        uuid: '',
        unique_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        country_id: 0,
        emirates_id: 0,
        school_id: 0,
        school_unique_id: '',
        section_id: 1,
        division_id: 1,
        grade_id: 1,
        image: '',
    },
    posStudentdata: {
        student_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        parent_email_id: '',
        country: '',
        location: '',
        school: '',
        grade: '',
        section: '',
        rfid: '',
        balance: '',
        status: ''
    },
    rechargeStudentdata: {
        uuid: '',
        unique_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        country_id: 0,
        emirates_id: 0,
        school_id: 0,
        school_unique_id: '',
        section_id: 1,
        division_id: 1,
        grade_id: 1,
        image: '',
    },
    listdata: [],
    studentListdata: [],
    listPosdata: [],
    listPosdataForMeals: [],
    cardTransactions: [],
    rechargelist: [],
    lastupdate: new Date().getTime()
}

export const getStudentsList = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            // const { appdata } = getState();
            // if (appdata.pagination?.total > 0 && url === "") {
            //     appdata.pagination.links.forEach((link) => {
            //         if (link.active) {
            //             url = link.url;
            //         }
            //     })
            // }
            // const response = await getstudentslist({ url });
            // if (response) {
            //     let pagination = [];
            //     pagination.current_page = response.current_page;
            //     pagination.last_page = response.last_page;
            //     pagination.first_page_url = response.first_page_url;
            //     pagination.last_page_url = response.last_page_url;
            //     pagination.next_page_url = response.next_page_url;
            //     pagination.prev_page_url = response.prev_page_url;
            //     pagination.path = response.path;
            //     pagination.per_page = response.per_page;
            //     pagination.from = response.from;
            //     pagination.to = response.to;
            //     pagination.total = response.total;
            //     pagination.links = response.links;

            //     if (response?.total > 0 && response?.data.map) {
            //         let studentsList = [];
            //         response.data.forEach(({ students }) => {
            //             const { basic, division, grade, school, school_unique_id, section } = students;
            //             const emirates = basic?.emirates_mapping[0]?.emirates;
            //             let studentData = {
            //                 uuid: basic.uuid,
            //                 unique_id: basic.unique_id,
            //                 value: basic.uuid,
            //                 label: basic.first_name,
            //                 name: `${basic.first_name ? basic.first_name : ""} ${basic.middle_name ? basic.middle_name : ""} ${basic.last_name ? basic.last_name : ""}`,
            //                 first_name: basic.first_name,
            //                 middle_name: basic.middle_name,
            //                 last_name: basic.last_name,
            //                 image: basic.image,
            //                 country_id: basic.country.id,
            //                 country_name: basic.country.name,
            //                 emirates_id: emirates.id,
            //                 emirates_name: emirates.name,
            //                 school_id: school.id,
            //                 school_name: school.name,
            //                 school_unique_id: school_unique_id,
            //                 school_location: school.location,
            //                 section_id: section.id,
            //                 section_name: section.name,
            //                 division_id: division.id,
            //                 division_name: division.name,
            //                 grade_id: grade.id,
            //                 grade_name: grade.name,
            //                 status: basic.status,
            //             }
            //             studentsList.push(studentData)
            //         })
            //         dispatch(setPagination({ pagination: pagination }))
            //         dispatch(setstudentlist({ listdata: studentsList }))
            //         dispatch(setstudentlist({ studentListdata: studentsList }))
            //     }
            // }
            // if (response === 'Unauthorized.') {
            //     dispatch(logoutUser())
            // }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

export const getPosStudentsList = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {

            const store = getState();
            const { appdata } = store;

            if (appdata.pagination?.total > 0 && url === "") {
                appdata.pagination.links.forEach((link) => {
                    if (link.active) {
                        url = link.url;
                    }
                })
            }

            const response = await getPosStudentslist({ url });
            if (response) {

                let newpagination = [];
                newpagination.current_page = response.current_page;
                newpagination.last_page = response.last_page;
                newpagination.first_page_url = response.first_page_url;
                newpagination.last_page_url = response.last_page_url;
                newpagination.next_page_url = response.next_page_url;
                newpagination.prev_page_url = response.prev_page_url;
                newpagination.path = response.path;
                newpagination.per_page = response.per_page;
                newpagination.from = response.from;
                newpagination.to = response.to;
                newpagination.total = response.total;
                newpagination.links = response.links;

                if (response?.total > 0 && response?.data.map) {
                    let studentsList = [];
                    response.data.forEach((students) => {
                        let studentData = {
                            uuid: null,
                            unique_id: students.student_id,
                            value: students.student_id,
                            label: students.first_name,
                            name: `${students.first_name ? students.first_name : ""} ${students.middle_name ? students.middle_name : ""} ${students.last_name ? students.last_name : ""}`,
                            first_name: students.first_name,
                            middle_name: students.middle_name,
                            last_name: students.last_name,
                            image: null,
                            country_id: null,
                            country_name: students.country,
                            emirates_id: null,
                            emirates_name: null,
                            school_id: students.school_id,
                            school_name: students.school,
                            location: students.location,
                            school_unique_id: null,
                            section_id: null,
                            section_name: students.section,
                            division_id: null,
                            division_name: null,
                            grade_id: null,
                            grade_name: students.grade,
                            rfid: students.rfid,
                            balance: students.balance || 0.00,
                            status: students.status,
                            canRecharge: true
                        }
                        studentsList.push(studentData)
                    })
                    dispatch(setPagination({ pagination: newpagination }))
                    dispatch(setposStudentlist({ listPosdata: studentsList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

export const getPosStudentsListForMealOrder = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {

            const response = await getPosStudentslistForMealOrder();
            if (response) {

                if (response?.length > 0 && response?.map) {
                    let studentsList = [];
                    response.forEach((students) => {
                        let studentData = {
                            uuid: null,
                            unique_id: students.student_id,
                            value: students.student_id,
                            label: students.first_name,
                            name: `${students.first_name ? students.first_name : ""} ${students.middle_name ? students.middle_name : ""} ${students.last_name ? students.last_name : ""}`,
                            first_name: students.first_name,
                            middle_name: students.middle_name,
                            last_name: students.last_name,
                            image: null,
                            country_id: null,
                            country_name: students.country,
                            emirates_id: null,
                            emirates_name: null,
                            school_id: students.school_id,
                            school_name: students.school,
                            location: students.location,
                            school_unique_id: null,
                            section_id: null,
                            section_name: students.section,
                            division_id: null,
                            division_name: null,
                            grade_id: null,
                            grade_name: students.grade,
                            rfid: students.rfid,
                            balance: students.balance || 0.00,
                            status: students.status,
                            canRecharge: true
                        }
                        studentsList.push(studentData)
                    })
                    dispatch(setposStudentlist({ listPosdataForMeals: studentsList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

export const getRechargeList = (payload, force = false) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const { appdata, userdata } = getState();
            let response;
            if (userdata.role_id == 1 || userdata.role_id == 2) {
                response = await getrechargeAdminlist(payload, force);
            } else {
                response = await getrechargelist(payload, force);
            }
            if (response) {
                if (response?.total > 0 && response?.data.map) {
                    let studentsList = [];
                    response.data.forEach((transaction) => {
                        let { student } = transaction;
                        let payment_response = {}
                        try {
                            payment_response = JSON.parse(transaction.payment_response)
                        } catch (err) { }
                        let status_code = payment_response?.fail_rc || transaction.status;
                        let trans_date = "";
                        if (payment_response?.txndatetime) {
                            let txndatetime = payment_response.txndatetime.split('-')[0];
                            if (txndatetime) {
                                let datetime = txndatetime.split(':');
                                trans_date = datetime[2] + "/" + datetime[1] + "/" + datetime[0]
                            }
                        }
                        let studentData = {
                            transaction_id: transaction.transaction_id,
                            amount: transaction.price,
                            balance: student.balance,
                            unique_id: student.student_id,
                            name: `${student.first_name ? student.first_name : ""} ${student.middle_name ? student.middle_name : ""} ${student.last_name ? student.last_name : ""}`,
                            student_name: `${student.first_name ? student.first_name : ""} ${student.middle_name ? student.middle_name : ""} ${student.last_name ? student.last_name : ""}`,
                            first_name: student.first_name,
                            middle_name: student.middle_name,
                            last_name: student.last_name,
                            parent_email_id: student.parent_email_id,
                            country_name: student.country,
                            school_name: student.school,
                            section_name: student.section,
                            grade_name: student.grade,
                            rfid: student.rfid,
                            status: status_code,
                            status_code: status_code,

                            ipgTransactionId: payment_response?.ipgTransactionId,
                            ccbrand: payment_response?.ccbrand,
                            txndatetime: trans_date,
                            paymentAccountReferenceNumber: payment_response?.paymentAccountReferenceNumber,

                            payment_status: getPaymentStatus(status_code),
                        }
                        
                        if (payment_response?.fail_reason) {
                            studentData.fail_reason = payment_response?.fail_reason
                        }
                        studentsList.push(studentData)
                    })
                    dispatch(setPagination({
                        apipagination: {
                            total: response.total,
                            page: response.current_page,
                            per_page: response.per_page
                        }
                    }))
                    dispatch(setposStudentlist({ rechargelist: studentsList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

export const getPosCardTransactions = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {

            const { appdata } = getState();
            if (appdata.pagination?.total > 0 && url === "") {
                appdata.pagination.links.forEach((link) => {
                    if (link.active) {
                        url = link.url;
                    }
                })
            }

            const response = await getPosCardTransactionslist({ url });
            if (response) {
                if (response?.total > 0 && response?.data.map) {
                    let cardTransactionsList = [];

                    let pagination = [];
                    pagination.current_page = response.current_page;
                    pagination.last_page = response.last_page;
                    pagination.first_page_url = response.first_page_url;
                    pagination.last_page_url = response.last_page_url;
                    pagination.next_page_url = response.next_page_url;
                    pagination.prev_page_url = response.prev_page_url;
                    pagination.path = response.path;
                    pagination.per_page = response.per_page;
                    pagination.from = response.from;
                    pagination.to = response.to;
                    pagination.total = response.total;
                    pagination.links = response.links;

                    response.data.forEach((card_transaction) => {

                        const { student } = card_transaction;

                        let transactionsData = {
                            unique_id: student.student_id,
                            name: `${student.first_name ? student.first_name : ""} ${student.middle_name ? student.middle_name : ""} ${student.last_name ? student.last_name : ""}`,
                            school_name: student.school,
                            section_name: student.section,
                            grade_name: student.grade,
                            rfid: student.rfid,
                            balance: student.balance,
                            invoice_id: card_transaction.invoice_id,
                            meal_detail: card_transaction.meal_detail,
                            price: card_transaction.price,
                            transaction_date: card_transaction.transaction_date,
                        }

                        cardTransactionsList.push(transactionsData)
                    })

                    dispatch(setPagination({ pagination: pagination }))
                    dispatch(setposStudentlist({ cardTransactions: cardTransactionsList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

export const getAdminStudentsList = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        // try {
        //     const store = getState();
        //     const { appdata } = store;

        //     if (appdata.pagination?.total > 0 && url === "") {
        //         appdata.pagination.links.forEach((link) => {
        //             if (link.active) {
        //                 url = link.url;
        //             }
        //         })
        //     }

        //     const response = await getadminstudentslist({ url });

        //     let pagination = [];
        //     pagination.current_page = response.current_page;
        //     pagination.last_page = response.last_page;
        //     pagination.first_page_url = response.first_page_url;
        //     pagination.last_page_url = response.last_page_url;
        //     pagination.next_page_url = response.next_page_url;
        //     pagination.prev_page_url = response.prev_page_url;
        //     pagination.path = response.path;
        //     pagination.per_page = response.per_page;
        //     pagination.from = response.from;
        //     pagination.to = response.to;
        //     pagination.total = response.total;
        //     pagination.links = response.links;

        //     let studentsList = [];
        //     response.data.forEach((students) => {
        //         const { student } = students;
        //         const emirates = student.basic?.emirates_mapping[0]?.emirates;
        //         let studentData = {
        //             uuid: student.basic.uuid,
        //             value: student.basic.uuid,
        //             label: student.basic.first_name,
        //             first_name: student.basic.first_name,
        //             middle_name: student.basic.middle_name,
        //             last_name: student.basic.last_name,
        //             country_id: student.basic.country.id,
        //             country_name: student.basic.country.name,
        //             emirates_id: emirates.id,
        //             emirates_name: emirates.name,
        //             school_id: student.school.id,
        //             school_name: student.school.name,
        //             school_unique_id: student.school_unique_id,
        //             section_id: student.section.id,
        //             section_name: student.section.name,
        //             division_id: student.division.id,
        //             division_name: student.division.name,
        //             grade_id: student.grade.id,
        //             grade_name: student.grade.name,
        //             status: student.basic.status,
        //         }
        //         studentsList.push(studentData)
        //     })
        //     dispatch(setPagination({ pagination: pagination }))
        //     dispatch(setstudentlist({ studentdata: studentsList }));

        // } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const getAdminPosStudentsList = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const store = getState();
            const { appdata } = store;

            if (appdata.pagination?.total > 0 && url === "") {
                appdata.pagination.links.forEach((link) => {
                    if (link.active) {
                        url = link.url;
                    }
                })
            }

            const response = await getAdminPosStudentslist({ url });
            let studentsList = [];

            let pagination = [];
            pagination.current_page = response.current_page;
            pagination.last_page = response.last_page;
            pagination.first_page_url = response.first_page_url;
            pagination.last_page_url = response.last_page_url;
            pagination.next_page_url = response.next_page_url;
            pagination.prev_page_url = response.prev_page_url;
            pagination.path = response.path;
            pagination.per_page = response.per_page;
            pagination.from = response.from;
            pagination.to = response.to;
            pagination.total = response.total;
            pagination.links = response.links;

            response.data.forEach((students) => {
                let studentData = {
                    student_id: students.student_id,
                    first_name: students.first_name,
                    middle_name: students.middle_name,
                    last_name: students.last_name,
                    parent_email_id: students.parent_email_id,
                    country: students.country,
                    location: students.location,
                    school: students.school,
                    grade: students.grade,
                    section: students.section,
                    rfid: students.rfid,
                    balance: students.balance,
                    status: students.status
                }
                studentsList.push(studentData)
            })

            dispatch(setPagination({ pagination: pagination }))
            dispatch(setstudentlist({ posStudentdata: studentsList }));

        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const getAdminPosStudentsListV2 = (payload, force=false) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await getAdminPosStudentslistv2(payload, force);
            let studentsList = [];

            response.data.forEach((students) => {
                let studentData = {
                    student_id: students.student_id,
                    first_name: students.first_name,
                    middle_name: students.middle_name,
                    last_name: students.last_name,
                    parent_email_id: students.parent_email_id,
                    country: students.country,
                    location: students.location,
                    school: students.school,
                    grade: students.grade,
                    section: students.section,
                    rfid: students.rfid,
                    balance: students.balance,
                    status: students.status
                }
                studentsList.push(studentData)
            })
            
            dispatch(setPagination({ apipagination: {
                total: response.total,
                page : response.current_page,
                per_page: response.per_page
            } }))
            dispatch(setstudentlist({ posStudentdata: studentsList }));

        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const addStudent = (formdata) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoading(true))
            let _formdata = { ...formdata, emirates_id: [formdata.emirates_id] }
            let isEdit = formdata?.uuid?.length > 0
            const response = isEdit ? await updatestudent(_formdata) : await addstudent(_formdata);
            if (response.status) {
                await dispatch(getStudentsList())
                await dispatch(resetform())
                await dispatch(setactivetab(0))
                dispatch(setActiveMenu('/students/students-list'))
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj))

            } else {
                let message = response.message
                if (response.errors) {
                    message = Object.values(response.errors)[0][0]
                }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const deleteStudent = (formdata) => {
    return async (dispatch, getState) => {

        dispatch(setLoading(true))
        try {
            let _formdata = { ...formdata, emirates_id: [formdata.emirates_id] }
            const response = await deletestudent(_formdata);
            if (response.status) {
                await dispatch(resetform())
                await dispatch(getStudentsList())
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj));
            } else {
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: response.message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) { }
        dispatch(setLoading(false))
    }
}


const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        setformdata: (state, action) => {
            return { ...state, studentdata: action.payload };
        },
        onedit: (state, action) => {
            return {
                ...state,
                studentdata: action.payload,
                isEdit: true
            };
        },
        onselect: (state, action) => {
            return {
                ...state,
                studentdata: action.payload,
            };
        },
        onRechargeSelect: (state, action) => {
            return {
                ...state,
                rechargeStudentdata: action.payload,
            };
        },
        resetRecharge: (state) => {
            return {
                ...state,
                rechargeStudentdata: initialState.rechargeStudentdata
            };
        },
        resetform: (state) => {
            return {
                ...state,
                studentdata: initialState.studentdata,
                isEdit: false
            };
        },
        setactivetab: (state, action) => {
            return { ...state, activetab: action.payload };
        },
        getstudentlist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setstudentlist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setposStudentlist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setrechargelist: (state, action) => {
            return { ...state, ...action.payload };
        },

    },
})

export const { setactivetab, setformdata, getstudentlist, setstudentlist, setposStudentlist, onedit, resetform, onselect, onRechargeSelect, resetRecharge } = studentsSlice.actions;

export default studentsSlice.reducer;
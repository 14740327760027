import React from "react";
import './loader.scss';
const Loader = () => {
    return (
        <div>
            <div className="loader-container">
                <div className="loader" >
                    <img src="/assets/images/loading.svg" alt="" className="img-fluid" />
                </div>
            </div>
            <div className="loader-overlay" />
        </div>
    )
}

export default Loader;
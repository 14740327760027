import React from "react";
import Header from "../components/Header";
import { Footer1 } from "../components/Footer";
import SideMenu from "../components/Menu";
import PrivacyPopup from "../components/PrivacyPopup";

const PrivateLayout = (props) => {
  return (
    <>
      <PrivacyPopup />
      <Header />
      <SideMenu />
      <div className="page-content">
        <div id="main-content" className="main-content">
          {props.children}
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=971507335181&amp;text=Hello, how may we help you? Just send us a message now to get assistance."
        className="whatsapp_chat"
        target="_blank"
        title="Let's Connect on Whatsapp"
        rel="noreferrer"
      >
        <i className="mdi mdi-whatsapp"></i>
      </a>
      <Footer1 />
    </>
  );
};

export default PrivateLayout;

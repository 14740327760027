import React from "react";

const Avatar = ({src, type, size, wrap = "yes"}) => {

    if(!src){
        if(type == 'a'){
            src = '/assets/images/avatar-1.jpg';
        }
        else if(type == 's'){
            src = '/assets/images/avatar-2.jpg';
        }
        else if(type == 'p'){
            src = '/assets/images/avatar.jpg';
        }
        else {
            src = '/assets/images/no-image.png';
        }
    }
    return (
        (wrap === "no") ? <img className={"rounded-circle avatar-" + size} src={src} /> : 
        <div>
            <img className={"rounded-circle avatar-" + size} src={src} />
        </div>
    )
}

export default Avatar;
import React from "react";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import './alert.scss';
export const alertTypes = {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    customsuccess: 'customsuccess',
    loginsuccess: 'loginsuccess',
    loginfaild: 'loginfaild',
    passwordfail: 'passwordfail',
}
const lastupdate = {};
const alert_interval = 5000
const timeout = 1500;
const AlertItem = ({ message, type }) => {
    if (lastupdate[type]) { return }
    lastupdate[type] = true;
    setTimeout(() => {
        lastupdate[type] = false
    }, alert_interval)
    switch (type) {
        case alertTypes.primary:
            return <div className="alert alert-primary alert-dismissible float-right" role="alert">{message}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /></div>
        case alertTypes.secondary:
            return <div className="alert alert-secondary alert-dismissible float-right" role="alert">{message}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /></div>
        case alertTypes.success:
            return <div className="alert alert-success alert-dismissible float-right" role="alert">{message}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /></div>
        case alertTypes.danger:
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong...!',
                text: message
            })
            break;
        case alertTypes.warning:
            return <div className="alert alert-warning alert-dismissible float-right" role="alert">{message}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /></div>
        case alertTypes.info:
            return <div className="alert alert-info alert-dismissible float-right mb-0" role="alert">{message}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /></div>
        case alertTypes.customsuccess:
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: timeout
            })
            break;
        case alertTypes.loginsuccess:
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your has been successfully sign in',
                showConfirmButton: false,
                timer: timeout
            })
            break;
        case alertTypes.loginfaild:
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong...!',
                text: message
            })
            break;
        case alertTypes.passwordfail:
            Swal.fire({
                icon: 'error',
                title: 'An error occured...',
                text: message
            })
            break;
        default:
            return <></>

    }
}
const Alert = (props) => {
    const { alert, notifications } = useSelector((store) => { return store.appdata });
    return (
        <div className="alert-container">
            <AlertItem {...alert} />
        </div>
    )
}

export default Alert;
import { createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../../app/components/Alert/Alert";
import { logindata_key } from "../../appkeys";
import { setActiveMenu, setAlert, setLoading, setPagination } from "../appReducer/appReducer";
import { adduser, deleteuser, sendVerificationMail, emailverification, getallemployees, getallparents, getalluser, loginuser, logoutuser, registeruser, updateuser, resetPassword, UserPasswordResetApi } from "../services/userservices"
import { getUserDetails, getDetailsComplimentary, getDetailsSchool, getDetailsMealselection, getComplimentaryBasedCategory, getDetailsStudent } from '../appReducer/appReducer';
import moment from "moment/moment";
const initialState = {
    email: '',
    password: '',
    role_name: 4,
    role_id: 4,
    accestoken: '',
    isAuth: false,
    email_verified:false,
    createuserdata: {
        uuid: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        emirates_id: [],
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        role_id: '',
    },
    listdata: [],
    parentslist:[],
    stafflist:[],
    lastupdate: new Date().getTime()
}

const localState = () => {
    let localAuthData = localStorage.getItem(logindata_key)    
    if (localAuthData) {
        try {
            let authData = JSON.parse(localAuthData)
            let expire_time = authData.expire_time;
            let date1 = parseInt(moment(expire_time).format('YYYYMMDDHHmmss'));
            let date2 = parseInt(moment().format('YYYYMMDDHmmss'));
            if (date1 > date2) {
                return authData
            }
            localStorage.removeItem(logindata_key)
        } catch (err) {
            return {};
        }
    }
    return {}
}

export const registerUser = (formdata => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await registeruser(formdata);
            if (response.data) {
                if (response.status) {
                    let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                    dispatch(setAlert(alertObj));
                    let newState = {
                        success: true,
                    }
                    dispatch(setActiveMenu('/'))
                    dispatch(onregisteruser(newState))
                } else {
                    let message = response.message
                    if (response.errors) {
                        message = Object.values(response.errors)[0][0]
                    }
                    let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                    dispatch(setAlert(alertObj));
                    let newState = {
                        success: false
                    }
                    dispatch(onregisteruser(newState))
                }
            } else {
                let message = response.message
                    if (response.errors) {
                        message = Object.values(response.errors)[0][0]
                    }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj))
                let newState = { success: false }
                dispatch(onregisteruser(newState))
            }
        } catch (err) { }
        dispatch(setLoading(false))
    }
}
)

export const userLogin = (formdata) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await loginuser(formdata);
            if (response.data && response.status) {                
                const _token = response.data.token;
                const _userdata = response.data.user;
                const role_mapping = _userdata.role_mapping
                const _role_name = role_mapping?.role?.name || '';
                const _role_id = role_mapping?.role?.id || 0;
                const expire_time = moment().add(12, 'h');
                let newState = {
                    ...initialState,
                    ..._userdata,
                    expire_time: expire_time,
                    accestoken: _token,
                    role_name: _role_name,
                    role_id: _role_id,
                    isAuth: true,
                }
                localStorage.setItem(logindata_key, JSON.stringify(newState));
                if (_role_id === 4) {
                    await dispatch(getDetailsStudent())
                } else if ([1, 2, 6, 7].includes(_role_id)) {
                    await dispatch(getUserDetails())
                    await dispatch(getDetailsComplimentary())
                    await dispatch(getDetailsSchool())
                    await dispatch(getDetailsMealselection())
                    await dispatch(getComplimentaryBasedCategory());
                }
                await dispatch(onloginsuccess(newState))                
                if(response.message==='mailVerify'){
                    await dispatch(setActiveMenu('/verify-email'))
                    await dispatch(setLoading(false))
                    return;
                } else {
                    await dispatch(setActiveMenu('/'))
                }
                let alertObj = { alert: { show: true, type: alertTypes.loginsuccess } }
                await dispatch(setAlert(alertObj));
            } else {
                let message = response.message;   
                const _token = response?.data?.token;             
                const expire_time = moment().add(12, 'h');
                let newState = {
                    ...initialState,
                    ...formdata,
                    accestoken: _token,
                    expire_time: expire_time,
                }
                if(message==='mailVerify'){
                    localStorage.setItem(logindata_key, JSON.stringify(newState));
                    await dispatch(setActiveMenu('/verify-email'))
                    await dispatch(onloginfaild(newState))
                    await dispatch(setLoading(false))
                    return;
                }
                if (response.errors) {
                    message = Object.values(response.errors)[0][0]
                }
                localStorage.removeItem(logindata_key);
                await dispatch(onloginfaild(newState))
                let alertObj = { alert: { show: true, type: alertTypes.loginfaild, message: message } }
                await dispatch(setAlert(alertObj))
            }
        } catch (err) { 
            localStorage.removeItem(logindata_key);
        }
        dispatch(setLoading(false))
    }
}

export const logoutUser = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const store = getState();
            const {userdata} = store;
            const response = await logoutuser(payload);
            let newState = {
                isLoading: false,
                error: response.message,
                isAuth: false,
				email_verified:userdata.email_verified,
            }
            localStorage.removeItem(logindata_key)
            localStorage.clear();
            await dispatch(setActiveMenu('/'));
            await dispatch(onloginsuccess(newState))
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const emailVerification = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const store = getState();
            const {userdata} = store;
            const response = await emailverification(payload);
            let newState = {
              ...userdata,
              email_verified: response.status,
            };
            localStorage.setItem(logindata_key, JSON.stringify(newState));
            await dispatch(onloginsuccess(newState));
            
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const resendVerificationMail = () => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            await sendVerificationMail();
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const passwordReset = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await resetPassword(payload);
            let message = response.message
            let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: message } }
            dispatch(setAlert(alertObj))
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const UserPasswordReset = (payload) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await UserPasswordResetApi(payload);
            if (!response.status) {
                if(response.errors?.new_password) {
                    let errorMessage = response.errors?.new_password;
                    let alertObj = { alert: { show: true, type: alertTypes.passwordfail, message: errorMessage } }
                    dispatch(setAlert(alertObj));

                } else {
                    let alertObj = { alert: { show: true, type: alertTypes.passwordfail, message: response.errors } }
                    dispatch(setAlert(alertObj));
                }
            } else {
                let message = response.message;
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: message } };
                dispatch(setAlert(alertObj));
            }
        } catch (err) { }
        dispatch(setLoading(false));
    }
}

export const addUser = (payload) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = payload.uuid ? await updateuser(payload) : await adduser(payload);
            if (response.status) {
                await dispatch(getAllUser())
                await dispatch(resetform())
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj))
            } else {
                let message = response.message
                if (response.errors) {
                    message = Object.values(response.errors)[0][0]
                }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const getAllUser = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const { appdata } = getState();
            if(appdata.pagination?.total > 0 && url === ""){
                appdata.pagination.links.forEach((link) => {
                    if(link.active){
                        url = link.url;
                    }
                })
            }

            const response = await getalluser({url});
            let userList = [];

            let pagination = [];
            pagination.current_page = response.current_page;
            pagination.last_page = response.last_page;
            pagination.first_page_url = response.first_page_url;
            pagination.last_page_url = response.last_page_url;
            pagination.next_page_url = response.next_page_url;
            pagination.prev_page_url = response.prev_page_url;
            pagination.path = response.path;
            pagination.per_page = response.per_page;
            pagination.from = response.from;
            pagination.to = response.to;
            pagination.total = response.total;
            pagination.links = response.links;

            response.data.forEach((user) => {
                const { email, uuid, first_name, middle_name, last_name, phone, status, emirates_mapping, role_mapping } = user;
                const emirates_id = emirates_mapping.map((em) => { return em.emirates.id });
                const emirates_name = emirates_mapping.map((emr) => { return emr.emirates.name });
                let userData = {
                    uuid: uuid,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    phone: phone,
                    email: email,
                    status: status,
                    emirates_id: emirates_id,
                    location_name: emirates_name,
                    role_id: role_mapping.role.id,
                    role_name: role_mapping.role.name
                }
                userList.push(userData);
            });
            dispatch(setPagination({ pagination: pagination }))
            dispatch(setuserlist({ listdata: userList }));
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const deleteUser = (formdata) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            let _formdata = { ...formdata, emirates_id: [formdata.emirates_id] }
            const response = await deleteuser(_formdata);
            if (response.status) {
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj));
                dispatch(getAllUser())
                dispatch(resetform())
            } else {
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: response.message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const getAllParents = () => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const store = getState();
            const {appdata} = store;
            if(appdata.country){
                
            }
            const response = await getallparents();            
            let parentsList = [];
            response.forEach((parent) => {
                const  { country_id, created_at, email, emirates_mapping, first_name, middle_name, image, last_name, phone, status, student_mapping, unique_id, uuid, role_mapping} = parent
                const roles = role_mapping.role.id;
                let studentsList = [];
                student_mapping.forEach(({ students }) => {
                    const { basic, division, grade, school, school_unique_id, section } = students;
                    const emirates = basic?.emirates_mapping[0]?.emirates;
                    let studentData = {
                        uuid:basic.uuid,
                        value: basic.uuid,
                        label:basic.first_name,
                        first_name: basic.first_name,
                        middle_name: basic.middle_name,
                        last_name: basic.last_name,
                        country_id: basic.country.id,
                        country_name: basic.country.name,
                        emirates_id: emirates.id,
                        emirates_name: emirates.name,
                        school_id: school.id,
                        school_name: school.name,
                        school_unique_id: school_unique_id,
                        section_id: section.id,
                        section_name: section.name,
                        division_id: division.id,
                        division_name: division.name,
                        grade_id: grade.id,
                        grade_name: grade.name,
                        status:basic.status,
                    }
                    studentsList.push(studentData)
                })
                let parentdata = {
                    country_id:country_id,
                    created_at:created_at,
                    email:email,
                    emirates_mapping:emirates_mapping,
                    first_name:first_name,
                    middle_name:middle_name,
                    image:image,
                    last_name:last_name,
                    phone:phone,
                    role_mapping:roles.id,
                    status:status,
                    unique_id:unique_id,
                    uuid:uuid,
                    students: studentsList,

                }

                parentsList.push(parentdata)

            })
            dispatch(setuserlist({ listdata: parentsList }));
        } catch (err) { }
        dispatch(setLoading(false))
    }
}

export const getAllEmployees = () => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await getallemployees();
            let employeeList = [];
            response.forEach((user) => {
                const { email, uuid, first_name, middle_name, last_name, phone, status, emirates_mapping, role_mapping } = user;
                const emirates_id = emirates_mapping.map((em) => { return em.emirates.id });
                const emirates_name = emirates_mapping.map((emr) => { return emr.emirates.name });
                let userData = {
                    uuid: uuid,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    phone: phone,
                    email: email,
                    status: status,
                    emirates_id: emirates_id,
                    location_name: emirates_name,
                    role_id: role_mapping.role.id,
                    role_name: role_mapping.role.name
                }
                employeeList.push(userData);
            });
            dispatch(setuserlist({ listdata: employeeList }));
        } catch (err) { }
        dispatch(setLoading(false))
    }
}
const userSlice = createSlice({
    name: 'userdata',
    initialState: { ...initialState, ...localState() },
    reducers: {
        onloginsuccess: (state, action) => {return { ...state, ...action.payload };},
        onloginfaild: (state, action) => {return { ...state, ...action.payload };},
        onregisteruser: (state, action) => {return { ...state, ...action.payload };},
        resetregister: (state, action) => { return { ...state, success: false, redirect: '' }; },
        onadduser: (state, action) => {return { ...state, ...action.payload }},
        setuserlist: (state, action) => {return {...state,...action.payload}},
        onedit: (state, action) => {return {...state,createuserdata: action.payload,isEdit: true,}},
        setformdata: (state, action) => {return { ...state, createuserdata: action.payload };},
        resetform: (state, action) => {
            return {
                ...state,
                createuserdata: initialState.createuserdata,
                isEdit: false
            };
        },
    },
})

export const { onlogin, onloginsuccess, onadduser, onloginfaild, onregisteruser, setuserlist, resetform, onedit, resetregister, setformdata } = userSlice.actions;

export default userSlice.reducer;
export const logindata_key = 'logindata'

export const row_actions = {
    View: 'View',
    Edit: 'Edit',
    Copy: 'Copy',
    DeActivate: 'De Activate',
    Delete: 'Delete',
    MealOrder: 'Meal Order',
    Recharge: 'Recharge',
    Repay: 'Re Pay',
}
export const header_types = {
    string: 'string',
    number: 'number',
    float: 'float',
    date: 'date',
    date_str: 'date_str',
    image: 'image',
    bool: 'bool',
    status: 'status',
    payment: 'payment',
    payment_str: 'payment_str',
    student_details: 'student_details',
}
import { makeSequreApiCall, METHOD_GET, METHOD_POST, METHOD_DELETE } from './callapi';

const prefix = 'api/v1/admin/meal-selection/';

export const getall = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}
export const create = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const update = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix + payload.id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const deletemeal = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(prefix + payload.id, METHOD_DELETE, payload, force).then(resolve).catch(reject);
    })
}
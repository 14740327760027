import { makeSequreApiCall, METHOD_GET, METHOD_POST, METHOD_DELETE } from './callapi';

const prefix = '/api/v1/admin/'
const school = prefix + 'school/'
const complimentary = prefix + 'complimentary/'
const status = 'status/';

export const getallschools = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(school, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}
export const createschool = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(school, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const updateschool = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(school + payload.id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const deleteschool = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(school + payload.id, METHOD_DELETE, payload, force).then(resolve).catch(reject);
    })
}
export const getallcomplimentary = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(payload?.url ? payload.url : complimentary, METHOD_GET, payload, force).then(resolve).catch(reject);
    })
}
export const createcomplimentary = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(complimentary, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const updatecomplimentary = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(complimentary + payload.id, METHOD_POST, payload, force).then(resolve).catch(reject);
    })
}
export const deletecomplimentary = (payload, force=false) => {
    return new Promise((resolve, reject) => {
        return makeSequreApiCall(complimentary + payload.id, METHOD_DELETE, payload, force).then(resolve).catch(reject);
    })
}

export const lan_key = {
    dashboard: "dashboard",
    student: "student",
    students:"students",
    create: "create",
    student_list: "student_list",
    pos_student_list: "pos_student_list",
    meal_manage: "meal_manage",
    order_now: "order_now",
    meal_orders: "meal_orders",
    meal_summary: "meal_summary",
    payments: "payments",
    recharge: "recharge",
    transactions: "transactions",
    postransactions:"postransactions",
    summary: "summary",
    notifications: "notifications",
    no_notifications: "no_notifications",
    settings: "settings",
    profile: "profile",
    logout: "logout",
    password: "password",
    student_details: "student_details",
    class_details: "class_details",
    upload_photo: "upload_photo",
    confirm_details: "confirm_details",    
    name: "name",
    first_name: "first_name",
    last_name: "last_name",
    school: "school",
    school_id: "school_id",
    country: "country",
    emirates: "emirates",
    section: "section",
    grade: "grade",
    class: "class",
    division: "division",
    previous: "previous",
    next: "next",
    clear: "clear",
    upload: "upload",
    rendered: "rendered",
    file: "file",
    confirm: "confirm",
    Please_confirm_details: "Please_confirm_details",
    created: "created",
    add_new_student: "add_new_student",
    status: "status",
    balance: "balance",
    aed_balance:"aed_balance",
    rfid: "rfid",
    action: "action",
    meal_order: "meal_order",
    select_student: "select_student",
    weekly_menu: "weekly_menu",
    order_summary: "order_summary",
    grand_total: "grand_total",
    total_pay: "total_pay",
    menu: "menu",
    card_transactions: "card_transactions",
    parent_portal: "parent_portal",
    available_online: "available_online",
    total_students: "total_students",
    total_odered: "total_odered",
    available_for_meal_order:"available_for_meal_order",
    available_for_online_recharge:"available_for_online_recharge",
    total_online_recharge_amount:"total_online_recharge_amount",
    total_meal_order_amount:"total_meal_order_amount",
    total_meal_orders:"total_meal_orders",
    profile_details:"profile_details",
    user_id:"user_id",
    created_date:"created_date",
    email_id:"email_id",
    phone:"phone",
    view_student_list:"view_student_list",
    active:"active",
    in_active:"in_active",
    update_password:"update_password",
    confirm_password:"confirm_password",
    submit:"submit",
    total_pos_balance:"total_pos_balance",
    student_recharge:"student_recharge",
    meal_order_kart:"meal_order_kart",
    order_list:"order_list",
    total_meal_sel_menu_list:"total_meal_sel_menu_list",
    edit:"edit",
    active_students:"active_students",
    clear_data_confirmation:"clear_data_confirmation",
    confirm_button:"confirm_button",
    cancel:"cancel",
    clear_form:"clear_form",
    total:"total",
    showing:"",
    to:"to",
    of:"of",
    entries:"entries",
    image:"image",
    search:"search",
    enter_the_last_digit_of:"enter_the_last_digit_of",
    no_record:"no_record",
    meal_order_now:"meal_order_now",
    meal_order_students:"meal_order_students",
    create_student:"create_student",
    are_you_sure:"are_you_sure",
    you_wont_be_able_to:"you_wont_be_able_to",
    holiday: "holiday",
    no_meal_available: "no_meal_available",
    enter_first_name:"enter_first_name",
    enter_last_name:"enter_last_name",
    enter_school_ID:"enter_school_ID",
    recharge_portal:"recharge_portal",
    recharge_now:"recharge_now",
    online_recharge:"online_recharge",
    recharge_transaction:"recharge_transaction",
    wallet_balance:"wallet_balance",
    view_card_transactions:"view_card_transactions",
    bill_date : "bill_date",
    recharge_amount : "recharge_amount",
    bill_amount : "bill_amount",
    amount_paid : "amount_paid",
    rfid_card_no : "rfid_card_no",
    item_details : "item_details",
    student_name:"student_name",
    date:"date",
    middle_name:"middle_name",
    enter_middle_name:"enter_middle_name",
    new_password:"new_password",
    current_password:"current_password",
    recharge_students:"recharge_students",
    rfid_transactions:"rfid_transactions",
    total_balance:"total_balance",
    location:"location",
    transaction_id: 'transaction_id',
    amount:'amount',
    fail_reason:'fail_reason',
    refresh:'refresh',
    total_students_for_meal_order : "total_students_for_meal_order",
    finish : "finish",
    excel : "excel",
    pdf : "pdf",
    id : "id",
    aed : "aed",
    not_available : "not_available",
    order_id : "order_id",
    meal_date : "meal_date",
    school_name : "school_name",
    category_name : "category_name",
    meal_name : "meal_name",
    nutrition : "nutrition",
    payment_date : "payment_date",
    payment_mode : "payment_mode",
    success : "success",
    cancelled : "cancelled",
    declined : "declined",
    failed : "failed",
    paid : "paid",
    meal_order_details : "meal_order_details",
    student_detail : "student_detail",
    student_id : "student_id",
    invoice : "invoice",
    meal_detail : "meal_detail",
    ipg_transaction_id : "ipg_transaction_id",
    card_type : "card_type",
    payment_account_reference : "payment_account_reference",
    recharge_transactions : "recharge_transactions",
    confirm_payment : "confirm_payment",
    enter_recharge_amount : "enter_recharge_amount",
    please_enter_minimum : "please_enter_minimum",
    to_procced_payment : "to_procced_payment",
    card_recharge : "card_recharge",
    no_meal_selected : "no_meal_selected",
    total_amount_pay : "total_amount_pay",
    please_confirm_and_proceed : "please_confirm_and_proceed"
}
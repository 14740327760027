import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../../app/components/Alert/Alert";
import { logindata_key } from "../../appkeys";
import { setActiveMenu, setAlert, setLoading, setPagination } from "../appReducer/appReducer";
import { adduser, getallemployees, getallparents, getalluser, loginuser, logoutuser, registeruser, updateuser } from "../services/userservices"
import { getUserDetails, getDetailsComplimentary, getDetailsSchool, getDetailsMealselection, getComplimentaryBasedCategory, getDetailsStudent } from '../appReducer/appReducer';
const initialState = {
    email: '',
    password: '',
    role_name: 4,
    role_id: 4,
    accestoken: '',
    isAuth: false,
    createuserdata: {
        uuid: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        emirates_id: [],
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        role_id: '',
    },
    listdata: [],
    parentslist:[],
    stafflist:[],
    lastupdate: new Date().getTime()
}

const localState = () => {
    let hasData = localStorage.getItem(logindata_key)
    if (hasData) {
        try {
            return JSON.parse(hasData)
        } catch (err) {
            return {};
        }
    }
    return {}
}

export const getAllEmployees = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {

            const { appdata } = getState();
            if(appdata.pagination?.total > 0 && url == ""){
                appdata.pagination.links.forEach((link) => {
                    if(link.active){
                        url = link.url;
                    }
                })
            }

            const response = await getallemployees({url});
            let employeeList = [];

            let pagination = [];
            pagination.current_page = response.current_page;
            pagination.last_page = response.last_page;
            pagination.first_page_url = response.first_page_url;
            pagination.last_page_url = response.last_page_url;
            pagination.next_page_url = response.next_page_url;
            pagination.prev_page_url = response.prev_page_url;
            pagination.path = response.path;
            pagination.per_page = response.per_page;
            pagination.from = response.from;
            pagination.to = response.to;
            pagination.total = response.total;
            pagination.links = response.links;

            response.data.forEach((user) => {
                const { email, uuid, first_name, middle_name, last_name, phone, status, emirates_mapping, role_mapping } = user;
                const emirates_id = emirates_mapping.map((em) => { return em.emirates.id });
                const emirates_name = emirates_mapping.map((emr) => { return emr.emirates.name });
                let employeedata = {
                    uuid: uuid,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    phone: phone,
                    email: email,
                    status: status,
                    emirates_id: emirates_id,
                    location_name: emirates_name,
                    role_id: role_mapping.role.id,
                    role_name: role_mapping.role.name
                }
                employeeList.push(employeedata);

            });
            dispatch(setPagination({ pagination: pagination }))
            dispatch(setemployeelist({ employeedata: employeeList }));
        } catch (err) { }
        dispatch(setLoading(false))
    }
}
const userSlice = createSlice({
    name: 'employeedata',
    initialState: { ...initialState, ...localState() },
    reducers: {
        onloginsuccess: (state, action) => {
            return { ...state, ...action.payload };
        },
        onloginfaild: (state, action) => {
            return { ...state, ...action.payload };
        },
        onregisteruser: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetregister: (state, action) => { return { ...state, success: false, redirect: '' }; },
        onadduser: (state, action) => {
            return { ...state, ...action.payload }
        },
        setemployeelist: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        onedit: (state, action) => {
            return {
                ...state,
                createuserdata: action.payload,
                isEdit: true,
            }
        },
        setformdata: (state, action) => {
            return { ...state, createuserdata: action.payload };
        },
        resetform: (state, action) => {
            return {
                ...state,
                createuserdata: initialState.createuserdata,
                isEdit: false
            };
        },
    },
})

export const { onlogin, onloginsuccess, onadduser, onloginfaild, onregisteruser, setemployeelist, resetform, onedit, resetregister, setformdata } = userSlice.actions;

export default userSlice.reducer;
import React from 'react';
import { Component } from "react";
export default class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if(process.env.NODE_ENV === 'development'){
      console.log(error, errorInfo);
    }
    return false
  }

  render() {
    return this.props.children;
  }
}
import { combineReducers, createSlice } from "@reduxjs/toolkit";
import appReducer from "./appReducer/appReducer";
import masterReducer from "./masterReducer";
import mealReducer from "./mealReducer";
import menuReducer from "./menuReducer";
import studentsReducer from "./studentsReducer";
import  userReducer from "./userReducer/userReducer";
import  parentReducer from "./userReducer/parentReducer";
import employeeReducer from "./userReducer/employeeReducer";
import dataTableReducer from "../../app/v2/store/dataTableReducer";

const rootReducer = combineReducers({
    appdata:appReducer,
    userdata: userReducer,
    parentdata: parentReducer,
    employeedata: employeeReducer,
    students: studentsReducer,
    masterdata: masterReducer,
    mealdata: mealReducer,
    menudata: menuReducer,
    datatable: dataTableReducer,
})

export default rootReducer;
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { lan_key } from "../../config/lan_keys";
import { getRoutesByRoleId } from "../../config/route/RoutesConfig";
import { setActiveMenu, resetPagination } from "../../config/store/appReducer/appReducer";
import { resetform } from "../../config/store/studentsReducer";
import { resetform as userResetform } from "../../config/store/userReducer/userReducer"
import { resetform as mealResetform } from "../../config/store/mealReducer";
import { useEffect } from "react";


const SideMenu = () => {
    const { role_id } = useSelector((store) => { return store.userdata })
    const { activemenu } = useSelector((store) => { return store.appdata })
    const isMobile = window.screen.width < 1024
    const {t}  = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const menuData = getRoutesByRoleId(role_id);
    let _isleaf = false;
    const onClickMenu = (path, isleaf) =>{ 
        let isActive = activemenu?.indexOf(path) > -1;
        if(isMobile && isleaf){
            document.body.classList.remove('sidebar-enable')
        }
        if (isActive && !isleaf) {
            dispatch(setActiveMenu(''))
        } else {
            dispatch(setActiveMenu(path))
        }

        if (!isActive && isleaf) {
            dispatch(resetform())
            dispatch(userResetform())
            dispatch(mealResetform())
            dispatch(resetPagination())
        }
        _isleaf = isleaf
    }
    const MenuItem = (props) => {
        const menu = props.menuItem;
        let isActive = activemenu?.indexOf(menu.path) > -1;
        let _className = isActive ? "mm-active" : "";
        if (isActive && props.isSubMenu) {
            _className = "active"
        }
        if(menu.disabled){
            return;
        }
        return (
            <li key={menu.path} className={_className}>
                {menu?.subRoutes ? 
                <a key={menu.path} to={menu.path} onClick={() => onClickMenu(menu.path)} className={menu?.subRoutes ? "has-arrow waves-effect" : "waves-effect"}>
                    <i className={menu.icon}></i>
                    <span key="t-default">{menu.name}</span>
                </a> :
                <Link key={menu.path} onClick={() => onClickMenu(menu.path, true)} to={menu.path} className={menu?.subRoutes ? "has-arrow waves-effect" : "waves-effect"}>
                    <i className={menu.icon}></i>
                    <span key="t-default">{menu.name}</span>
                    {menu.sub_title && <span className="sub_title">{menu.sub_title}</span>}
                </Link>}
                {menu?.subRoutes ? SubMenuItem(menu.subRoutes, isActive) : null}
            </li>
        )
    }
    const SubMenuItem = (menu, isActive = false) => {
        return (
            <ul key={'sub-menu'} className={"sub-menu mm-collapse" + (isActive ? " mm-show" : "")} aria-expanded="false">
                {menu?.map((menuItem, key) => {
                    return <MenuItem key={'sub_' + key} menuItem={menuItem} isSubMenu={true} />
                })}
            </ul>
        )
    }

    useEffect(()=>{
        if (_isleaf && activemenu !== window.location.pathname) {
            navigate(activemenu)
        }
    },[activemenu])

    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" key="side-menu" id="side-menu">
                        <li className="menu-title" key="t-menu">{t(lan_key.menu)}</li>
                        {menuData.map((menuItem, key) => {
                            return <MenuItem key={"main_" + key} menuItem={menuItem} />
                        })}
                    </ul>
                </div>
                <div className="simplebar-placeholder"></div>
                <div className="simplebar-track simplebar-horizontal" ><div className="simplebar-scrollbar"></div></div>
                <div className="simplebar-track simplebar-vertical"><div className="simplebar-scrollbar"></div></div>
            </div>
        </div>
    );
}
export default SideMenu;
import { createSlice } from "@reduxjs/toolkit"
import { alertTypes } from "../../app/components/Alert/Alert";
import { getSelectedValue } from "../../app/utils/helper";
import { setAlert, setDetailData, setLoading, setPagination } from "./appReducer/appReducer";
import { createcomplimentary, createschool, deletecomplimentary, deleteschool, getallcomplimentary, getallschools, updatecomplimentary, updateschool } from "./services/masterservices";
import { logoutUser } from "./userReducer/userReducer";

const initialState = {
    schooldata: {
        id:"",
        school_id : "",
        name: "",
        address: "",
        emirates_id: 1,
        location: "",
        phone: "",
        email: "",
    },
    complimentarydata: {
        id: '',
        name: "",
        meal_category_id:1,
    },
    schools:[],
    complimentarys:[],
    isEdit: false,
    lastupdate: new Date().getTime()
}
export const getAllSchools = (payload, force=false) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {

            const { appdata } = getState();
            const response = await getallschools(payload, force);
            if (response) {

                if (response?.total > 0 && response?.data.map) {
                    const emirates = appdata.emirates;
                    let schoolList = [];
                    response.data.forEach((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "school_id"
                        obj.emirates_id = obj.emirate_id
                        obj.emirates_name = getSelectedValue(obj.emirate_id, emirates).label
                        schoolList.push(obj)
                    })
                    dispatch(setPagination({ apipagination: {
                        total: response.total,
                        page : response.current_page,
                        per_page: response.per_page
                    } }))
                    dispatch(setDetailData({ schools: schoolList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const createSchool = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            let _payload = {...payload, emirate_id: payload.emirates_id}
            const response = payload.id ? await updateschool(_payload) :  await createschool(_payload);
            if (response.status) {
                await dispatch(getAllSchools())
                await dispatch(resetform())
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj))
            } else {
                let message = response.message
                if(response.errors){
                    message = Object.values(response.errors)[0][0]
                }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const deleteSchool = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await deleteschool(payload);
            if (response.status) {
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj));
                dispatch(getAllSchools())
            } else {
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: response.message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const getAllComplimentary = (url = "") => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {

            const { appdata } = getState();
            if(appdata.pagination?.total > 0 && url == ""){
                appdata.pagination.links.forEach((link) => {
                    if(link.active){
                        url = link.url;
                    }
                })
            }

            const response = await getallcomplimentary({url});
            if (response) {
                if (response?.total > 0 && response?.data.map) {
                    const mealCategory = appdata.meal_category;
                    let complimentaryList = [];

                    let pagination = [];
                    pagination.current_page = response.current_page;
                    pagination.last_page = response.last_page;
                    pagination.first_page_url = response.first_page_url;
                    pagination.last_page_url = response.last_page_url;
                    pagination.next_page_url = response.next_page_url;
                    pagination.prev_page_url = response.prev_page_url;
                    pagination.path = response.path;
                    pagination.per_page = response.per_page;
                    pagination.from = response.from;
                    pagination.to = response.to;
                    pagination.total = response.total;
                    pagination.links = response.links;

                    response.data.forEach((complimentary) => {
                        complimentary.meal_category_name = getSelectedValue(complimentary.meal_category_id, mealCategory).label
                        complimentaryList.push(complimentary)
                    })

                    dispatch(setPagination({ pagination: pagination }))
                    dispatch(setcomplimentarylist({ complimentarys: complimentaryList }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const createComplimentary = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = payload.id?  await updatecomplimentary(payload) :  await createcomplimentary(payload);
            if (response.status) {
                await dispatch(getAllComplimentary())
                await dispatch(resetform())
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj))
            } else {
                let message = response.message
                if(response.errors){
                    message = Object.values(response.errors)[0][0]
                }
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}
export const deleteComplimentary = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await deletecomplimentary(payload);
            if (response.status) {
                let alertObj = { alert: { show: true, type: alertTypes.customsuccess, message: response.message } }
                dispatch(setAlert(alertObj));
                dispatch(getAllComplimentary())
            } else {
                let alertObj = { alert: { show: true, type: alertTypes.danger, message: response.message } }
                dispatch(setAlert(alertObj));
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        dispatch(setLoading(false))
    }
}

const masterSlice = createSlice({
    name: 'masterslice',
    initialState,
    reducers: {
        setschoolformdata: (state, action) => {
            return { ...state, schooldata: action.payload };
        },
        setcomplimentaryformdata: (state, action) => {
            return { ...state, complimentarydata: action.payload };
        },
        oneditschool: (state, action) => {
            return {
                ...state,
                schooldata: action.payload,
                complimentarydata: {},
                isEdit: true
            };
        },
        oneditcomplimentary: (state, action) => {
            return {
                ...state,
                schooldata: {},
                complimentarydata: action.payload,
                isEdit: true
            };
        },
        resetform: (state, action) => {
            return {
                ...state,
                schooldata: initialState.schooldata,
                complimentarydata: initialState.complimentarydata,
                isEdit: false
            };
        },
        getschoolslist: (state, action) => {
            return { ...state, ...action.payload };
        },
        getcomplimentarylist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setschoolslist: (state, action) => {
            return { ...state, ...action.payload };
        },
        setcomplimentarylist: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
})

export const { setschoolformdata, setcomplimentaryformdata, oneditschool, oneditcomplimentary, resetform, getschoolslist, getcomplimentarylist, setschoolslist, setcomplimentarylist, } = masterSlice.actions;

export default masterSlice.reducer;
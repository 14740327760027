import { createSlice } from "@reduxjs/toolkit"
import { getAll } from "../mealReducer";
import { dashboardData, getuserdetails, getdetailscomplimentary, getdetailsschool, getdetailsmealselection, getcomplimentarybasedcategory, getdetailsstudent } from "../services/general"
import { logoutUser } from "../userReducer/userReducer";
const lastupdate = {};
const initialState = {
    isLoading: 0,
    activemenu: window.location.pathname,
    alert: {
        show: false,
        type: '',
        message: ''
    },
    notifications: {
        showNotiFication: false,
        message: {
            type: '',
            message: ''
        }
    },
    emirates: [],
    roles: [],
    emirates: [],
    meal_category: [],
    schools: [],
    complimentary: [],
    countries: [],
    divisions: [],
    emirates: [],
    grades: [],
    schools: [],
    sections: [],
    pagination: {
        current_page: 1,
        last_page: "",
        first_page_url: "",
        last_page_url: "",
        next_page_url: "",
        prev_page_url: "",
        path: "",
        per_page: 15,
        from: 1,
        to: "",
        total: 0,
        links: []
    },
    apipagination: {
        per_page: 10,
        sort_by: undefined,
        sort_type: undefined,
        from_date: undefined,
        to_date: undefined,
        key_word: undefined,
        filter_by: undefined,
        page: 1,
        total: 0,
    },
    dashboard:[]
}

export const getUserDetails = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getuserdetails(payload);
            if (response) {
                if (response?.emirates) {
                    let emirates = response.emirates.map((obj) => {
                        obj.value = obj.id
                        obj.label = obj.name
                        obj.key = "emirates_id"
                        return obj
                    })
                    dispatch(setDetailData({ emirates: emirates }))
                }
                if (response?.roles) {
                    let roles = response.roles.map((obj) => {
                        obj.value = obj.id
                        obj.label = obj.name
                        obj.key = "role_id"
                        return obj
                    })
                    dispatch(setDetailData({ roles: roles }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getDetailsComplimentary = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getdetailscomplimentary(payload);
            if (response) {
                //todo api issue
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getDetailsSchool = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getdetailsschool(payload);
            if (response) {
                if (response?.emirates) {
                    let emirates = response.emirates.map((obj) => {
                        obj.value = obj.id
                        obj.label = obj.name
                        obj.key = "emirates_id"
                        return obj
                    })
                    dispatch(setDetailData({ emirates: emirates }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getDashboardData = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await dashboardData(payload); 
            if (response) {
                dispatch(setDetailData({ dashboard: response }))
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getDetailsMealselection = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getdetailsmealselection(payload);
            if (response?.meal_category) {
                let meal_category = response.meal_category.map((obj) => {
                    obj.value = obj.id
                    obj.label = obj.name
                    obj.key = "meal_category_id"
                    return obj
                })
                await dispatch(setDetailData({ meal_category: meal_category }))
            }
            if (response?.schools) {
                let schools = response.schools.map((obj) => {
                    obj.value = obj.id
                    obj.label = obj.name
                    obj.key = "school_id"
                    return obj
                })
                await dispatch(setDetailData({ schools: schools }))
            }
            await dispatch(getAll())
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getComplimentaryBasedCategory = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getcomplimentarybasedcategory(payload);
            if (response) {
                if (response?.complimentary) {
                    let complimentary = response.complimentary.map((obj) => {
                        obj.value = obj.id
                        obj.label = obj.name
                        obj.key = "complimentaryMeal_id"
                        return obj
                    })
                    dispatch(setDetailData({ complimentary: complimentary }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}
export const getDetailsStudent = (payload) => {
    return async (dispatch, getState) => {
        // dispatch(setLoading(true))
        try {
            const response = await getdetailsstudent();
            if (response) {
                if (response.countries) {
                    let countries = response.countries.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "country_id"
                        if (obj.code === 'AE' || obj.name === 'United Arab Emirates') {
                            obj.isDefault = true
                        }
                        return obj
                    })
                    dispatch(setDetailData({ countries: countries }))
                }
                if (response.divisions) {
                    let divisions = response.divisions.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "division_id"
                        if (obj.id === 1) {
                            obj.isDefault = true
                        }
                        return obj
                    })
                    dispatch(setDetailData({ divisions: divisions }))
                }
                if (response.emirates) {
                    let emirates = response.emirates.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "emirates_id"
                        if (obj.id === 1) {
                            obj.isDefault = true
                        }
                        return obj
                    })
                    dispatch(setDetailData({ emirates: emirates }))
                }
                if (response.grades) {
                    let grades = response.grades.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "grade_id"
                        return obj
                    })
                    dispatch(setDetailData({ grades: grades }))
                }
                if (response.schools) {
                    let schools = response.schools.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "school_id"
                        return obj
                    })
                    dispatch(setDetailData({ schools: schools }))
                }
                if (response.sections) {
                    let sections = response.sections.map((obj) => {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        obj.key = "section_id"
                        return obj
                    })

                    dispatch(setDetailData({ sections: sections }))
                }
            }
            if (response === 'Unauthorized.') {
                dispatch(logoutUser())
            }
        } catch (err) {
        }
        // dispatch(setLoading(false))
    }
}

export const setAlert = (payload) => {
    return async (dispatch, getState) => {
        if (lastupdate[payload.type]) { return; }
        lastupdate[payload.type] = true;
        setTimeout(() => { lastupdate[payload.type] = false }, 1000)
        dispatch(setAlertData(payload))
        setTimeout(() => {
            let errObj = {
                alert: {
                    show: false,
                    message: '',
                    type: ''
                }
            }
            dispatch(setAlertData(errObj))
        }, 3000)
    }
}
const appSlice = createSlice({
    name: 'appdata',
    initialState,
    reducers: {
        setLoading: (state, action) => ({ ...state, isLoading: action.payload === true ? state.isLoading + 1 : state.isLoading - 1 }),
        setAlertData: (state, action) => ({ ...state, ...action.payload }),
        setActiveMenu: (state, action) => ({ ...state, activemenu: action.payload }),
        setDetailData: (state, action) => ({ ...state, ...action.payload }),
        setPagination: (state, action) => ({ ...state, ...action.payload }),
        resetPagination: (state) => ({ ...state, pagination: initialState.pagination })
    }
})
const { setAlertData } = appSlice.actions;
export const { setLoading, setActiveMenu, setDetailData, setPagination, resetPagination } = appSlice.actions;

export default appSlice.reducer;
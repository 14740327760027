import React from 'react';
const Footer = () => {
    return (
        <div className="mt-2 text-center">
            <div>
                {/* <p>© {new Date().getFullYear()}  Bite Paprika. Developed by <a href="https://webelabs.com/" target="new"> <img src="assets/images/webelabs2.svg" className="webelabs" alt="www.webelabs.com" /></a></p> */}
            </div>
        </div>
    );
}
const Footer1 = () => {
    return (
        <footer className="footer" id="ftr">
            <div className="row">
                <div className="col-sm-12">© {new Date().getFullYear()} Fresh Bite.  v2.0</div>
                {/* <div className="col-sm-6">
                    <div className="text-sm-end d-none d-sm-block">
                        <p> Developed by <a href="https://webelabs.com/" target="new"> <img src="/assets/images/webelabs2.svg" className="webelabs" alt="www.webelabs.com" /></a>
                        </p>
                    </div>
                </div> */}
            </div>
        </footer>
    );
}
export default Footer;
export { Footer1 };

